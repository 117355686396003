import React, { useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
    BLUE_THEME, GRAY_BORDER_UX,
    GRAY_PDF,
    GRAY_STRONG_UX,
    LINE_TABLE,
    ROW_MAIN,
    WHITE_TABLE,
    WHITE_THEME_BLACK
} from "../../shared/utils";
import FilterSingleOption from "../Filter/FilterSingleOption";
import GrayButton from "../Buttons/GrayButton";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import InputSearch from "../Input/InputSearch";
import AnexoPreviewModal from "../Modal/AnexoPreviewModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import AnexoUploaderModal from "../Modal/AnexoUploaderModal";


const AnexosReceita = (props) => {
    const {anexos, setAnexos, isTransferencia, setPrevAnexos = () => {}, setChangesList = () => {}, changesList = []} = props;
    const [openModal, setOpenModal] = useState(false);
    const [openUploaderModal, setOpenUploaderModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const handleClick = () => {
        setOpenUploaderModal(true);
    };

    const nullifyFile = (row, idx) => {
        setAnexos(anexos.map((anexo_aux, idx_aux) => {
            if (idx_aux === idx) {
                anexo_aux.arquivo = null;
            }
            return anexo_aux;
        }));
    };

    const handleRemoveAnexo = (idx) => {
        anexos.splice(idx, 1);
        setAnexos([...anexos]);
    };

    return (
        <React.Fragment>
            <AnexoPreviewModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                file={selectedFile}
            />
            <AnexoUploaderModal
                openModal={openUploaderModal}
                setOpenModal={setOpenUploaderModal}
                setAnexos={setAnexos}
                changesList={changesList}
                setChangesList={setChangesList}
                anexos={anexos}
                setPrevAnexos={setPrevAnexos}
            />
            <Grid container spacing={isTransferencia ? 0 : 2} sx={{ml: isTransferencia ? 0 : 0.5}}>
                <Grid item xs={12}>
                    <TableContainer className={"table-container"}>
                        <Table>
                            <TableHead style={{backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF}}>
                                <TableRow>
                                    <TableCell
                                        align={"center"}
                                        style={{
                                        ...{zIndex: 3},
                                        ...{fontWeight: "bold"},
                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                        ...{color: temaEscuro && LINE_TABLE},
                                        ...{padding: 5}}}
                                    >
                                        Forma do anexo
                                    </TableCell>
                                    <TableCell
                                        align={"center"}
                                        style={{
                                        ...{zIndex: 3},
                                        ...{fontWeight: "bold"},
                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                        ...{color: temaEscuro && LINE_TABLE},
                                        ...{padding: 5}}}
                                    >
                                        Anexo
                                    </TableCell>
                                    <TableCell
                                        align={"center"}
                                        style={{
                                        ...{zIndex: 3},
                                        ...{fontWeight: "bold"},
                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                        ...{color: temaEscuro && LINE_TABLE},
                                        ...{padding: 5}}}
                                    >
                                        Tipo de anexo
                                    </TableCell>
                                    <TableCell
                                        align={"center"}
                                        style={{
                                        ...{zIndex: 3},
                                        ...{fontWeight: "bold"},
                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                        ...{color: temaEscuro && LINE_TABLE},
                                        ...{padding: 5}}}
                                    >
                                        Descrição
                                    </TableCell>
                                    {
                                        anexos.length > 1 && (
                                            <TableCell/>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    anexos.map((anexo, idx) => {
                                        return(
                                            <TableRow key={idx} style={{backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE}}>
                                                <TableCell align={"center"}>
                                                    <FilterSingleOption
                                                        filterOptions={["Arquivo", "Link"]}
                                                        selectedOption={anexo.forma_anexo}
                                                        setSelectedOption={(value) => (setAnexos(anexos.map((anexo_aux, idx_aux) => {
                                                            if (idx_aux === idx) {
                                                                anexo_aux.forma_anexo = value;
                                                            }
                                                            return anexo_aux;
                                                        })))}
                                                        placeholder={"Forma do anexo"}
                                                    />
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    <Grid container spacing={2}>
                                                        {
                                                            !anexo.arquivo && (
                                                                <Grid item>
                                                                    <GrayButton
                                                                        testId="button-anexar-certificado"
                                                                        title="Arquivo"
                                                                        onClick={() => handleClick()}
                                                                        startIcon={<AttachFileOutlinedIcon/>}
                                                                    />
                                                                </Grid>
                                                            )
                                                        }
                                                        <Grid item>
                                                            {
                                                                anexo.arquivo &&
                                                                    <Grid style={{...styles.styleFieldImport, color: temaEscuro && WHITE_THEME_BLACK, display: "flex", alignItems: "center"}}>
                                                                        <VisibilityIcon
                                                                            sx={{cursor: "pointer"}}
                                                                            fontSize="small"
                                                                            onClick={() => {
                                                                                setSelectedFile(anexo.arquivo);
                                                                                setOpenModal(true);
                                                                                }
                                                                            }
                                                                        />
                                                                        <span>Arquivo: {anexo.arquivo?.name}</span>
                                                                        <Grid item sx={{padding: 0, cursor: "pointer"}} onClick={() => nullifyFile(anexo, idx)}>
                                                                            <ClearIcon
                                                                                fontSize="small"
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    <FilterSingleOption
                                                        filterOptions={["Contrato", "Documento Fiscal", "Documento de Cobrança", "Outros"]}
                                                        selectedOption={anexo.tipo_anexo}
                                                        setSelectedOption={(value) => (setAnexos(anexos.map((anexo_aux, idx_aux) => {
                                                            if (idx_aux === idx) {
                                                                anexo_aux.tipo_anexo = value;
                                                            }
                                                            return anexo_aux;
                                                        })))}
                                                        placeholder={"Tipo de anexo"}
                                                    />
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    <InputSearch
                                                        functionProps={() => {}}
                                                        setSearchValue={(value) => (setAnexos(anexos.map((anexo_aux, idx_aux) => {
                                                            if (idx_aux === idx) {
                                                                anexo_aux.descricao = value;
                                                            }
                                                            return anexo_aux;
                                                        })))}
                                                        searchValue={anexo.descricao}
                                                        className="input-text"
                                                        padding={0.5}
                                                        width={"500px"}
                                                        label={"Descrição"}
                                                    />
                                                </TableCell>
                                                {
                                                    anexos.length > 1 && (
                                                        <TableCell
                                                            align={"center"}
                                                            style={{padding: 0, cursor: "pointer"}}
                                                            onClick={() => handleRemoveAnexo(idx)}
                                                        >
                                                            <a>X</a>
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default AnexosReceita;

const styles = {
    styleFieldImport: {
        color : GRAY_STRONG_UX
    },

    formControl: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: GRAY_BORDER_UX
            },
        }
    },
};