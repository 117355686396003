import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import GrayButton from "../Buttons/GrayButton";
import {
  BLUE_THEME,
  BORDER_TABLE,
  formatterCurrency,
  WHITE_ESCRITA_THEME,
  GRAY_LABEL_UX_THEME,
  YELLOW_BG_UX,
  GRAY_BG_UX,
} from "../../shared/utils";
import VXMF from "../../containers/Contas/LogoBancos/VXMF.png";
import AddIcon from "@mui/icons-material/Add";

const BlocoLancamentoVXMF = ({ conciliacao }) => {
  const nav = useNavigate();

  const [descricao, setDescricao] = useState(conciliacao.extrato?.descricao);
  const [curConciliacao, setCurConciliacao] = useState(conciliacao);
  const temaEscuro = useSelector((state) => state.AuthReducer.temaEscuro);

  const handleRedirect = (url, valor, data, descricao) => {
    const date = data.split("/");
    const newDate = new Date(date[2], date[1] - 1, date[0]);
    nav(url, {
      state: { valor: valor, data: newDate, descricao: descricao },
    });
  };

  const handleEditMovimentacao = (movimentacao) => {
    const routeMap = {
      Transferência: "/editar_transferencia",
      Despesa: "/editar_despesa",
      Receita: "/editar_despesa",
    };

    const route = routeMap[movimentacao.tipo_movimentacao];

    if (route) {
      nav(route, { state: { id_movimentacao: movimentacao.id } });
    }
  };

  const handleUnlinkMovimentacao = () => {
    setCurConciliacao({
      ...curConciliacao,
      movimentacao: null,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item>
            <img src={VXMF} />
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12}>
                <a style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Lançamentos do VXMF
                </a>
              </Grid>
              <Grid item xs={12}>
                <a style={{ fontSize: "12px" }}>Voxel - Menu Financeiro</a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Grid
            container
            sx={{
              backgroundColor: temaEscuro ? BLUE_THEME : GRAY_LABEL_UX_THEME,
              backgroundImage: temaEscuro && "none",
              p: 3,
              width: "100%",
              height: 370.5,
            }}
          >
            {curConciliacao.movimentacao === null ? (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <a style={{ fontSize: "18px", fontWeight: "bold" }}>
                      Nenhum lançamento encontrado
                    </a>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      border: 0,
                      borderBottom: temaEscuro
                        ? `1px solid ${BORDER_TABLE}`
                        : `1px solid ${WHITE_ESCRITA_THEME}`,
                      ml: 2,
                    }}
                  />
                  <Grid item xs={12}>
                    <TextField
                      label={"Descrição"}
                      id="input-observacoes-textfield"
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      maxRows={6}
                      value={descricao}
                      onChange={(event) => {
                        setDescricao(event.target.value);
                      }}
                      sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                    }}
                  >
                    <GrayButton
                      title={
                        curConciliacao.extrato.valor_lancamento < 0
                          ? "Nova despesa"
                          : "Nova receita"
                      }
                      onClick={() =>
                        handleRedirect(
                          curConciliacao.extrato.valor_lancamento < 0
                            ? "/nova_despesa"
                            : "/nova_receita",
                          curConciliacao.extrato.valor_lancamento,
                          curConciliacao.extrato.data_lancamento,
                          curConciliacao.extrato.descricao
                        )
                      }
                    />
                    <GrayButton
                      title={"Nova transferência"}
                      onClick={() =>
                        handleRedirect(
                          "/nova_transferencia",
                          curConciliacao.extrato.valor_lancamento,
                          curConciliacao.extrato.data_lancamento,
                          curConciliacao.extrato.descricao
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <a style={{ fontSize: "18px", fontWeight: "bold" }}>
                      Lançamento sugerido
                    </a>
                  </Grid>
                  <Grid item xs={12}>
                    <a style={{ fontSize: "12px" }}>
                      Compare os dados antes de conciliar. Clique em Desvincular
                      se a sugestão não corresponder ao lançamento correto.
                    </a>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      border: 0,
                      borderBottom: temaEscuro
                        ? `1px solid ${BORDER_TABLE}`
                        : `1px solid ${WHITE_ESCRITA_THEME}`,
                      ml: 2,
                    }}
                  />
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <a style={{ fontSize: "18px", fontWeight: "bold" }}>
                          {curConciliacao.movimentacao.data_movimentacao}
                        </a>
                      </Grid>
                      <Grid item xs={12}>
                        <a style={{ fontSize: "12px" }}>Segunda-feira</a>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    <a>
                      {formatterCurrency(
                        curConciliacao.movimentacao.valor_total,
                        2
                      )}
                    </a>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <a
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: YELLOW_BG_UX,
                          }}
                        >
                          Compra de produto:{" "}
                          {curConciliacao.movimentacao.codigo_referencia}
                        </a>
                      </Grid>
                      <Grid item xs={12}>
                        <a style={{ fontSize: "12px" }}>
                          Descrição: {curConciliacao.movimentacao.descricao}
                        </a>
                      </Grid>
                      <Grid item xs={12}>
                        <a style={{ fontSize: "12px" }}>
                          Categoria:{" "}
                          {curConciliacao.movimentacao.nome_categoria}
                        </a>
                      </Grid>
                      <Grid item xs={12}>
                        <a style={{ fontSize: "12px" }}>
                          Fornecedor:{" "}
                          {curConciliacao.movimentacao.nome_fornecedor}
                        </a>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item>
                            <a style={{ fontSize: "12px" }}>
                              Descontos:{" "}
                              {formatterCurrency(
                                curConciliacao.movimentacao.desconto,
                                2
                              )}
                            </a>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      border: 0,
                      borderBottom: temaEscuro
                        ? `1px solid ${BORDER_TABLE}`
                        : `1px solid ${WHITE_ESCRITA_THEME}`,
                      ml: 2,
                    }}
                  />
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Grid item>
                        <GrayButton
                          title={"Editar"}
                          startIcon={<AddIcon />}
                          onClick={() =>
                            handleEditMovimentacao(curConciliacao.movimentacao)
                          }
                        />
                      </Grid>
                      <Grid item>
                        <GrayButton
                          title={"Desvincular"}
                          startIcon={<AddIcon />}
                          onClick={handleUnlinkMovimentacao}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlocoLancamentoVXMF;
