import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import FilterDayMonthYear from "../Filter/FilterDayMonthYear";
import {
    BLUE_THEME,
    DASH_KPI_NEGATIVE_COLOR,
    DASH_KPI_POSITIVE_COLOR,
    formatterCurrency,
    getCurrentDate,
    GRAY_LABEL_UX,
    styleText,
    WHITE_ESCRITA_THEME, GRAY_LABEL_UX_THEME,
    YELLOW_BG_UX,
} from "../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import InputSearch from "../Input/InputSearch";
import BlocoLancamentoVXMF from "./BlocoLancamentoVXMF";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConciliacaoContaKPI from "../Dash/ConciliacaoContaKPI";
import GrayButton from "../Buttons/GrayButton";
import api from "../../axios";
import {showSnackMessage} from "../../actions/SnackActions";
import BancoBrasil from "../../containers/Contas/LogoBancos/BB.png";
import Inter from "../../containers/Contas/LogoBancos/Inter.png";
import Skeleton from "@mui/material/Skeleton";


const ConciliacoesConta = (props) => {

    const dispatch = useDispatch();
    const {apelidoConta, contaId} = props;

	const currentMonthDate = moment(getCurrentDate()).toDate();
    const [selectedDate, setSelectedDate] = useState(currentMonthDate);
    const [searchValue, setSearchValue] = useState("");
    const [todosKPI, setTodosKPI] = useState(0);
    const [recebidosKPI, setRecebidosKPI] = useState(0);
    const [pagamentosKPI, setPagamentosKPI] = useState(0);
    const [conciliacoesConta, setConciliacoesConta] = useState([]);
    const [loading, setLoading] = useState(false);

    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        getConciliacoesConta();
    }, [selectedDate]);

    const getConciliacoesConta = (clear = false) => {
        setLoading(true);
        let dataRequest = {
            estabelecimento_id: estabelecimentoId,
            conta_id: contaId,
        };
        if (selectedDate instanceof Date) {
            dataRequest.data_competencia = moment(selectedDate).format("YYYY-MM-DD");
        } else {
            dataRequest.data_competencia = selectedDate;
        }
        if (searchValue !== "" & !clear) {
            dataRequest.search_value = searchValue;
        }
        api.GetConciliacaoConta(dataRequest).then(response => {
            let data = response.data;
            if (!data) {
                dispatch(showSnackMessage({ message: "Nenhuma conciliação a ser feita manualmente para esta data!", severity: "warning" }));
            } else {
                setConciliacoesConta(data.conciliacao);
                setTodosKPI(data.total_kpi);
                setRecebidosKPI(data.recebidos_kpi);
                setPagamentosKPI(data.pagamentos_kpi);
            }
            setLoading(false);
        });
    };

    const handleConciliar = (extratoId, movimentacaoId) => {
        setLoading(true);
        let dataRequest = {
            estabelecimento_id: estabelecimentoId,
            conta_id: contaId,
            extrato_id: extratoId,
            movimentacao_id: movimentacaoId,
        };
        api.PostConciliarConta(dataRequest).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Conciliação realizada com sucesso!", severity: "success" }));
            getConciliacoesConta();
        });

    };

    const handleDayMonthYear = (ano, mes, dia, setState) => {
        setState(`${ano}-${mes}-${dia}`);
    };

    const handleRemoveRow = (conciliacao) => {
        api.ignoreLancamento({extrato_id: conciliacao.extrato.id}).then(() => {
            conciliacoesConta.splice(conciliacao.id, 1);
            setConciliacoesConta([...conciliacoesConta]);
        });
    };

    const handleClearFilters = () => {
        setSearchValue("");
        getConciliacoesConta(true);
    };

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <FilterDayMonthYear
                                style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                value={selectedDate}
                                setState={setSelectedDate}
                                label={"Dia do mês"}
                                iconPosition={"end"}
                                size={"small"}
                                handleSubmit={handleDayMonthYear}
                                disableMinDate
                            />
                        </Grid>
                        <Grid item>
                            <InputSearch
                                callFunction
                                EXM
                                functionProps={getConciliacoesConta}
                                setSearchValue={setSearchValue}
                                searchValue={searchValue}
                                label={"Pesquisar"}
                            />
                        </Grid>
                        <Grid item sx={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                            <DeleteForeverIcon sx={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}/>
                            <a style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}} onClick={() => handleClearFilters()}>Limpar filtros</a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{marginBottom: 10}}>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <ConciliacaoContaKPI
                                title={"Todos"}
                                data={todosKPI}
                                selectedKPI
                                color={YELLOW_BG_UX}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ConciliacaoContaKPI
                                title={"Recebidos"}
                                data={recebidosKPI}
                                color={DASH_KPI_POSITIVE_COLOR}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ConciliacaoContaKPI
                                title={"Pagamentos"}
                                data={pagamentosKPI}
                                color={DASH_KPI_NEGATIVE_COLOR}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {
                    loading ? (
                        <Grid item xs={12}>
                            <Skeleton variant="rectangular" height={500} width="100%" />
                        </Grid>
                    ) : (
                        (
                            conciliacoesConta.map((conciliacao, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Grid item xs={5} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                    <Grid item>
                                                        {conciliacao.extrato.banco_nome === "Banco do Brasil" ? (
                                                            <img src={BancoBrasil} alt="Banco do Brasil" />
                                                        ) : conciliacao.extrato.banco_nome === "Inter" ? (
                                                            <img src={Inter} alt="Inter" />
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    height: "40px",
                                                                    padding: "0px 10px",
                                                                    backgroundColor: YELLOW_BG_UX,
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    fontWeight: "bold",
                                                                    fontSize: "14px",
                                                                    color: "black",
                                                                    borderRadius: "5px",
                                                                }}
                                                            >
                                                                {conciliacao.extrato.banco_nome.toUpperCase()}
                                                            </div>
                                                        )}
                                                    </Grid>
                                                        <Grid item>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <a style={{fontSize: "18px", fontWeight: "bold"}}>Lançamentos importados</a>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <a style={{fontSize: "12px"}}>{apelidoConta}</a>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: 4}}>
                                                    <Grid container sx={{
                                                        backgroundColor : temaEscuro ? BLUE_THEME : GRAY_LABEL_UX_THEME,
                                                        backgroundImage: temaEscuro && "none",
                                                        p: 3, width: "100%", height: 370.5
                                                    }}
                                                    >
                                                        <Grid item xs={6}>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <a style={{fontSize: "18px", fontWeight: "bold"}}>{conciliacao.extrato.data_lancamento}</a>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <a style={{fontSize: "12px"}}>Segunda-feira</a>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{display: "flex", justifyContent: "end"}}>
                                                            <a>{formatterCurrency(conciliacao.extrato.valor_lancamento, 2)}</a>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <a>{conciliacao.extrato.descricao}</a>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "end", alignItems: "end"}}>
                                                            <a style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => handleRemoveRow(conciliacao)}>Ignorar</a>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                            <GrayButton
                                                title={"Conciliar"}
                                                onClick={() => handleConciliar(conciliacao.extrato.id, conciliacao.movimentacao.id)}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <BlocoLancamentoVXMF conciliacao={conciliacao} />
                                        </Grid>
                                    </React.Fragment>
                                );
                            })
                        )
                    )
                }
            </Grid>
        </React.Fragment>
    );
};

export default ConciliacoesConta;