import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FilterSingleOption from "../Filter/FilterSingleOption";
import InputNumber from "../Input/InputNumber";
import {BLUE_THEME, BORDER_TABLE, smallPaperStyle, WHITE_ESCRITA_THEME} from "../../shared/utils";
import {useSelector, useDispatch} from "react-redux";
import InputSearch from "../Input/InputSearch";
import YellowButton from "../Buttons/YellowButton";
import GrayButton from "../Buttons/GrayButton";
import api from "../../axios";
import {showSnackMessage} from "../../actions/SnackActions";
import { Checkbox, FormControlLabel } from "@mui/material";



const CreateOptionModal = (props) => {
    const dispatch = useDispatch();
    const {openModal, setOpenModal, option, getInputsOptions} = props;
    const [tipoPessoaFornecedor, setTipoPessoaFornecedor] = useState("Jurídica");
    const [valueCpfCnpj, setValueCpfCnpj] = useState("");
    const [nomeFornecedor, setNomeFornecedor] = useState("");
    const [emailFornecedor, setEmailFornecedor] = useState("");
    const [telefoneFornecedor, setTelefoneFornecedor] = useState("");
    const [enderecoFornecedor, setEnderecoFornecedor] = useState("");
    const [inputError, setInputError] = useState(false);

    const [nomeCategoria, setNomeCategoria] = useState("");
    const [tipoCategoria, setTipoCategoria] = useState("");

    const [nomeProdutoServico, setNomeProdutoServico] = useState("");
    const [tipoProdutoServico, setTipoProdutoServico] = useState("Produto");

    const [nomeCentroCusto, setNomeCentroCusto] = useState("");
    const [codigoCentroCusto, setCodigoCentroCusto] = useState("");

    const [isCliente, setIsCliente] = useState(false);
    const [isFornecedor, setIsFornecedor] = useState(false);
    const [isTransportadora, setIsTransportadora] = useState(false);

    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        if (option === "cliente") {
            setIsCliente(true);
            setIsFornecedor(false);
            setIsTransportadora(false);
        }
        else if (option === "fornecedor") {
            setIsFornecedor(true);
            setIsCliente(false);
            setIsTransportadora(false);
        }
        else if (option === "transportadora") {
            setIsTransportadora(true);
            setIsCliente(false);
            setIsFornecedor(false);
        }
        setInputError(false);
    }, [openModal]);

    const handleCreate = () => {
        if (option === "fornecedor" || option === "cliente" || option === "transportadora") {
            if (tipoPessoaFornecedor === "" || nomeFornecedor === "") {
                setInputError(true);
                return;
            }
            let dataRequest = {
                estabelecimento_id: estabelecimentoId,
                is_cliente: isCliente,
                is_fornecedor: isFornecedor,
                is_transportadora: isTransportadora,
                tipo_pessoa: tipoPessoaFornecedor,
                cpf_cnpj: valueCpfCnpj,
                nome: nomeFornecedor,
                email: emailFornecedor,
                telefone: telefoneFornecedor,
                endereco: enderecoFornecedor
            };
            api.PostCreateFornecedores(dataRequest).then((response) => {
                setOpenModal(false);
                getInputsOptions();
                dispatch(showSnackMessage({message: response.data.message, severity: "success"}));
                props?.getCadastroInfo();
            }).catch(() => {
                dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
            });
        } else if (option === "categoria") {
            if (nomeCategoria === "" || tipoCategoria === "") {
                setInputError(true);
                return;
            }
            let dataRequest = {
                estabelecimento_id: estabelecimentoId,
                nome: nomeCategoria,
                tipo: tipoCategoria
            };
            api.PostCreateCategoria(dataRequest).then((response) => {
                setOpenModal(false);
                getInputsOptions();                
                dispatch(showSnackMessage({message: response.data.message, severity: "success"}));
                props?.getCadastroInfo();
            }).catch(() => {
                dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
            });
        } else if (option === "produtoServico") {
            if (nomeProdutoServico === "" || tipoProdutoServico === "") {
                setInputError(true);
                return;
            }
            let dataRequest = {
                estabelecimento_id: estabelecimentoId,
                nome_produto_servico: nomeProdutoServico,
                tipo_produto_servico: tipoProdutoServico
            };
            api.PostCreateProdutoServico(dataRequest).then((response) => {
                setOpenModal(false);
                getInputsOptions();
                dispatch(showSnackMessage({message: response.data.message, severity: "success"}));
                props?.getCadastroInfo();
            }).catch(() => {
                dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
            });
        } else if (option === "centroCusto") {
            if (nomeCentroCusto === "" || codigoCentroCusto === "") {
                setInputError(true);
                return;
            }
            let dataRequest = {
                estabelecimento_id: estabelecimentoId,
                nome: nomeCentroCusto,
                codigo: codigoCentroCusto
            };
            api.PostCreateCentroCustos(dataRequest).then((response) => {
                setOpenModal(false);
                getInputsOptions();
                dispatch(showSnackMessage({message: response.data.message, severity: "success"}));
                props?.getCadastroInfo();
            }).catch(() => {
                dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
            });
        }
    };

    return(
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="info-modal"
            >
                <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, pt: 3, width: "50%"}}>
                    <Grid container spacing={3}>
                        <Grid item>
                            {(option === "fornecedor" || option === "cliente" || option === "transportadora") && (
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <a>Novo Cadastro</a>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <a>Dados Gerais</a>
                                            </Grid>
                                            <Grid item xs={12} sx={{
                                                border: 0,
                                                borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                                mb: 2.5,
                                                ml: 2
                                            }}/>
                                            <Grid item>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox
                                                            checked={isCliente}
                                                            onChange={(e) => setIsCliente(e.target.checked)}
                                                            inputProps={{ "aria-label": "controlled" }}
                                                        />
                                                    } 
                                                    label="Cliente"
                                                    sx={{
                                                        "& .MuiTypography-root": {
                                                            color: temaEscuro && WHITE_ESCRITA_THEME,
                                                            fontSize: "15px",
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox
                                                            checked={isFornecedor}
                                                            onChange={(e) => setIsFornecedor(e.target.checked)}
                                                            inputProps={{ "aria-label": "controlled" }}
                                                        />
                                                    } 
                                                    label="Fornecedor"
                                                    sx={{
                                                        "& .MuiTypography-root": {
                                                            color: temaEscuro && WHITE_ESCRITA_THEME,
                                                            fontSize: "15px",
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox
                                                            checked={isTransportadora}
                                                            onChange={(e) => setIsTransportadora(e.target.checked)}
                                                            inputProps={{ "aria-label": "controlled" }}
                                                        />
                                                    } 
                                                    label="Transportadora"
                                                    sx={{
                                                        "& .MuiTypography-root": {
                                                            color: temaEscuro && WHITE_ESCRITA_THEME,
                                                            fontSize: "15px",
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FilterSingleOption
                                                    filterOptions={["Física", "Jurídica"]}
                                                    selectedOption={tipoPessoaFornecedor}
                                                    setSelectedOption={setTipoPessoaFornecedor}
                                                    placeholder={"Tipo de pessoa *"}
                                                    inputError={inputError}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <InputNumber
                                                    label={tipoPessoaFornecedor === "Física" ? "CPF" : "CNPJ"}
                                                    value={valueCpfCnpj}
                                                    setValue={setValueCpfCnpj}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <InputSearch
                                                    functionProps={() => {}}
                                                    setSearchValue={setNomeFornecedor}
                                                    searchValue={nomeFornecedor}
                                                    label={tipoPessoaFornecedor === "Física" ? "Nome *" : "Nome fantasia *"}
                                                    inputError={inputError}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <a>Infomações adicionais</a>
                                            </Grid>
                                            <Grid item xs={12} sx={{
                                                border: 0,
                                                borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                                mb: 2.5,
                                                ml: 2
                                            }}/>
                                            <Grid item>
                                                <InputSearch
                                                    functionProps={() => {}}
                                                    setSearchValue={setEmailFornecedor}
                                                    searchValue={emailFornecedor}
                                                    label={"E-mail"}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <InputNumber
                                                    label={"Telefone"}
                                                    value={telefoneFornecedor}
                                                    setValue={setTelefoneFornecedor}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <InputSearch
                                                    functionProps={() => {}}
                                                    setSearchValue={setEnderecoFornecedor}
                                                    searchValue={enderecoFornecedor}
                                                    label={"Endereço"}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            )}
                            {option === "categoria" && (
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <a>Cadastro de categoria</a>
                                    </Grid>
                                    <Grid item>
                                        <FilterSingleOption
                                            filterOptions={["Despesa", "Receita"]}
                                            selectedOption={tipoCategoria}
                                            setSelectedOption={setTipoCategoria}
                                            placeholder={"Tipo de categoria *"}
                                            inputError={inputError}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputSearch
                                            functionProps={() => {}}
                                            setSearchValue={setNomeCategoria}
                                            searchValue={nomeCategoria}
                                            label={"Nome da categoria *"}
                                            inputError={inputError}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            {option === "produtoServico" && (
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <a>Cadastro de produto/serviço</a>
                                    </Grid>
                                    <Grid item>
                                        <InputSearch
                                            functionProps={() => {}}
                                            setSearchValue={setNomeProdutoServico}
                                            searchValue={nomeProdutoServico}
                                            label={"Nome do produto/serviço *"}
                                            inputError={inputError}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FilterSingleOption
                                            filterOptions={["Produto", "Serviço"]}
                                            selectedOption={tipoProdutoServico}
                                            setSelectedOption={setTipoProdutoServico}
                                            placeholder={"Tipo de produto/serviço *"}
                                            inputError={inputError}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            {option === "centroCusto" && (
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <a>Cadastro de centro de custo</a>
                                    </Grid>
                                    <Grid item>
                                        <InputSearch
                                            functionProps={() => {}}
                                            setSearchValue={setNomeCentroCusto}
                                            searchValue={nomeCentroCusto}
                                            label={"Nome do centro de custo *"}
                                            inputError={inputError}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputSearch
                                            functionProps={() => {}}
                                            setSearchValue={setCodigoCentroCusto}
                                            searchValue={codigoCentroCusto}
                                            label={"Código do centro de custo *"}
                                            inputError={inputError}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container sx={{display: "flex", justifyContent: "end"}} spacing={2}>
                                <Grid item>
                                    <GrayButton
                                        title={"Cancelar"}
                                        onClick={() => setOpenModal(false)}
                                    />
                                </Grid>
                                <Grid item>
                                    <YellowButton
                                        title={"Salvar"}
                                        onClick={() => handleCreate()}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );

};

export default CreateOptionModal;