import React, {useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {Link as LinkRouter} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import api from "../../axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {loginUser, logoutUser} from "../../actions/AuthActions";
import Loading from "../../components/Loading/Loading";
import InputPassword from "../../components/Input/InputPassword";
import CopyRight from "../../components/CopyRight/CopyRight";
import { 
    GRAY_LABEL_UX,
    RED_ERROR_UX,
    GRAY_HEADER_UX,
    styleYellowButton,
    LINE_TABLE,
    GRAY_LABEL_UX_THEME,
    BLACK_SUBITEM_NAVBAR,
    BORDER_PAPER,
    translateAutocomplete,
    textCompleteStyle,
    GRAY_BG_UX,
 } from "../../shared/utils";
import "./Login.css";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";
import {
    changeLojas,
    changeStore,
    changeStoreName,
} from "../../actions/StoreActions";
import YellowButton from "../../components/Buttons/YellowButton";
import {showSnackMessage} from "../../actions/SnackActions";
import { Autocomplete } from "@mui/material";


const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "Inter, cursive",
            color: GRAY_LABEL_UX,
            fontSize: "17px",
            fontWeight: "500",
        },
        button: {
            textTransform: "none",
        }
    },
});


export default function Login() {

    const token = Cookies.get("tk");

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [stores, setStores] = useState([]);
    const [inputLoja, setInputLoja] = useState("");

    // eslint-disable-next-line no-undef
    const [recaptcha, setRecaptcha] = useState(process.env.NODE_ENV === "test" ? "test" : "");

    const deviceToken = useSelector(state => state.AuthReducer.deviceToken);
    
    const handleRecaptchaChange = (value) => {
        setRecaptcha(value);
    };

    const handleSubmit = () => {
        const data = {
            username: email,
            password,
            recaptcha_token: recaptcha,
            device_token: deviceToken
        };
        setLoading(true);
        api.GetLogin(data).then(response => {
            let token = response.data.token;
            let lojas = response.data.lojas;
            setStores(lojas);
            dispatch(changeLojas(lojas));
            setLoading(false);
            dispatch(loginUser(token));
        }).catch(error => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                setPasswordError(true);
            }
        });
    };

    const validateEmail = (email) => {
        if (email === "") {
            setEmailError(false);
            return;
        }
        if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
    };

    const logout = () =>{
		api.Logout().then(() => {
			dispatch(logoutUser());
			window.location.reload();
		}).catch(async () => {
			dispatch(logoutUser());
			window.location.reload();
		});
	};

    const handleSelectedLoja = () => {
        const dataRequest = {
            loja_nome: inputLoja,
        };
        api.EnterWithLoja(dataRequest).then(response => {
            const data = response.data;
            dispatch(changeStoreName(data.loja_nome));
            dispatch(changeStore(data.loja_id));
        }).catch(() => {
            logout();
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    return (
        <Box 
            sx={{
                backgroundColor: GRAY_LABEL_UX_THEME,
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Grid 
                container 
                sx={{
                    backgroundColor: LINE_TABLE,
                    width: "500px", 
                    borderRadius: 3,
                    border: `1px solid ${BORDER_PAPER}`,
                    padding: 2,
                }}>
                {
                    (!token || stores.length == 0) ? (
                        <React.Fragment>
                            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <p style={styles.header}>Entre em sua conta</p>
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <h1 style={styles.text}>Insira as credenciais fornecidas para realizar o acesso ao sistema</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <ThemeProvider theme={theme}>
                                    <Box component="form" sx={{ marginTop: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <TextField
                                            data-testid="email-input"
                                            margin="normal"
                                            fullWidth
                                            label="Email"
                                            autoComplete="email"
                                            autoFocus
                                            onChange={(e) => {setEmail(e.target.value.trim()); validateEmail(e.target.value.trim());}}
                                            value={email}
                                            variant="outlined"
                                            error={emailError || passwordError}
                                            sx={{ 
                                                width: "80%",
                                            }}
                                        />
                                        {
                                            emailError && (
                                                <p data-testid="email-error" style={{color: RED_ERROR_UX, fontSize: "12px", marginTop: "-2px"}}>Insira um e-mail válido</p>
                                            )
                                        }
                                        <InputPassword label="Senha" password={password} error={passwordError} handleChange={(e) => setPassword(e.target.value)} />
                                        {
                                            passwordError && (
                                                <p data-testid="email-pwd-error" style={{color: RED_ERROR_UX, fontSize: "12px"}}>O email ou senha informados estão incorretos!</p>
                                            )
                                        }
                                        <Grid container sx={{marginTop: 2, marginBottom: 2}}>
                                            <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                <LinkRouter data-testid="forgot-password-button" to='/forgot_pwd' variant="body2" style={{textDecoration: "none", color: GRAY_LABEL_UX, fontWeight: "600", fontSize: "14px"}}>
                                                    Esqueci minha senha
                                                </LinkRouter>
                                            </Grid>
                                        </Grid>
                                        {/* eslint-disable-next-line no-undef */}
                                        <ReCAPTCHA data-testid="recaptcha" sitekey={process.env.REACT_APP_SECRET_KEY} onChange={handleRecaptchaChange} />
                                        {
                                            loading ? (
                                                <Loading />
                                            ) : (
                                                <ThemeProvider theme={theme}>
                                                    { (emailError || (recaptcha === "") || (password === "")) ? (
                                                        <Button
                                                            disabled
                                                            data-testid="unabled-submit-button"
                                                            type="submit"
                                                            fullWidth
                                                            variant="contained"
                                                            sx={{ ...styles.buttonSubmit }}
                                                            style={{fontWeight: "600"}}
                                                        >
                                                            Entrar
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            data-testid="submit-button"
                                                            type="submit"
                                                            fullWidth
                                                            variant="contained"
                                                            sx={{ ...styles.buttonSubmit }}
                                                            onClick={() => handleSubmit()}
                                                            style={styleYellowButton}
                                                        >
                                                            Entrar
                                                        </Button>
                                                    )
                                                    }
                                                </ThemeProvider>
                                            )
                                        }
                                    </Box>
                                </ThemeProvider>
                            </Grid>
                            <div style={styles.copyright}>
                                <CopyRight color="black"/>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <p style={{...styles.header, width: "auto"}}>Entre em sua conta</p>
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <h1 style={{...styles.text, color: BLACK_SUBITEM_NAVBAR}}>Selecione uma loja para visualizar o sistema</h1>
                            </Grid>
                            <Grid item xs={12} sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 4, marginBottom: 3}}>
                                <Autocomplete
                                    noOptionsText={translateAutocomplete}
                                    size="small"
                                    options={stores}
                                    slotProps={{
                                        paper: {style: {
                                            backgroundColor: GRAY_BG_UX, fontFamily: "Inter, sans-serif", color: "#4A5568",
                                        }}
                                    }}
                                    sx={{
                                        width: "80%",
                                    }}
                                    value={inputLoja}
                                    onChange={(e, loja) => setInputLoja(loja)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={textCompleteStyle}
                                            label={"Loja"}
                                        />
                                    )}
                                />
                                <YellowButton 
                                    title="confirmar"
                                    onClick={() => handleSelectedLoja()}
                                />
                            </Grid>
                        </React.Fragment>
                    )
                }
            </Grid>
        </Box>
    );
}

const styles = {
    center: {
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    logo: {
        userDrag: "none",
        userSelect: "none",
        pointerEvents: "none",
        width: "191px",
        height: "36px"
    },
    header: {
        width: "398px",
        height: "15px",
        fontFamily: "Kallisto",
        fontWeight: "bold",
        fontSize: "36px",
        lineHeight: "32px",
        color: GRAY_HEADER_UX,
    },
    text: {
        width: "411px",
        height: "50px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        color: GRAY_LABEL_UX,
        textAlign: "center",
    },
    copyright: {
        textAlign: "center",
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "end",
        paddingBottom: "40px"
    },
    buttonSubmit: {
        marginTop: 3,
        marginBottom: 2,
        width: "80%",
    }
};

