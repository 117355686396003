import React, {useEffect, useState} from "react";
import Header from "../../components/Titles/Header";
import Paper from "@mui/material/Paper";
import {
    PAPER_PADDING_THEME,
    paperStyle,
    DASH_KPI_POSITIVE_COLOR,
    LINE_TABLE,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    BORDER_TABLE,
    WHITE_ESCRITA_THEME,
    BLUE_THEME,
    YELLOW_BG_UX,
    format,
    BORDER_BUTTON,
    RED_INFO
} from "../../shared/utils";
import SelectEstabelecimento from "../../components/SelectEstabelecimento/SelectEstabelecimento";
import { Grid } from "@mui/material";
import { Skeleton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import api from "../../axios";
import {showSnackMessage} from "../../actions/SnackActions";
import {useNavigate} from "react-router-dom";


const Dashboard = () => {

    const dispatch = useDispatch();
    const nav = useNavigate();

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    const [loading, setLoading] = useState(false);

    const [valueVencidosReceita, setValueVencidosReceita] = useState(0.0);
    const [valueVencemHojeReceita, setValueVencemHojeReceita] = useState(0.0);
    const [valueRestanteMesReceita, setValueRestanteMesReceita] = useState(0.0);

    const [valueVencidosDespesa, setValueVencidosDespesa] = useState(0.0);
    const [valueVencemHojeDespesa, setValueVencemHojeDespesa] = useState(0.0);
    const [valueRestanteMesDespesa, setValueRestanteMesDespesa] = useState(0.0);

    useEffect(() => {
        if (estabelecimentoId !== null) {
            getDashboard();
        }
    }, [estabelecimentoId]);


    const getDashboard = () => {
        setLoading(true);
        const dataRequest = {
            estabelecimento_id: estabelecimentoId
        };
        api.GetDashboard(dataRequest).then((response) => {
            const data = response.data;
            setValueVencidosReceita(data.total_receitas.total_vencidos);
            setValueVencemHojeReceita(data.total_receitas.total_hoje);
            setValueRestanteMesReceita(data.total_receitas.total_a_vencer);
            setValueVencidosDespesa(data.total_despesas.total_vencidos);
            setValueVencemHojeDespesa(data.total_despesas.total_hoje);
            setValueRestanteMesDespesa(data.total_despesas.total_a_vencer);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    const stylesCard = {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        gap: 1,
        alignItems: "center", 
        justifyContent: "center",
        backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE,
        backgroundImage: temaEscuro && "none", 
        border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`,
        color: temaEscuro && WHITE_ESCRITA_THEME,
        borderRadius: 1.5,
        fontSize: "15px",
        padding: "15px 0px",
        height: "80%",
    };

    const stylesText = {
        fontWeight: "400",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
        cursor: "pointer",
    };

    const stylesPaper = {
        ...paperStyle,
        backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
        backgroundImage: temaEscuro && "none", 
        border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`
    };

    const stylesP = {
        margin: 0,
    };

    const handleOpenNovaReceita = () => {
        nav("/nova_receita");
    };

    const handleOpenNovaDespesa = () => {
        nav("/gerar_compra");
    };

    const goToExtrato = (tipo_extrato, kpi) => {
        nav(`/extrato_movimentacoes?tipoExtrato=${tipo_extrato}&kpi=${kpi}`);
    };

    return (
        <div className="main" >
			<Header title="Dashboard"/>
            <Paper
                sx={{...stylesPaper}}
                data-testid="perfil-screen"
            >
                {loading ? (
                    [...Array(5).keys()].map((i,d) =>{
                        return(
                            <Skeleton key={d} height={50}/>
                        );
                    })
                ) : (
                    estabelecimentoId === 0 ? (
                        <SelectEstabelecimento/>
                    ) : (
                        <Grid container sx={{display: "flex", gap: 2, paddingBottom: 1.4}}>
                            <Grid item xs>
                                <Grid container sx={{display: "flex", gap: 1.5}}>
                                    <Grid item xs={12} sx={{display: "flex", justifyContent: "space-between"}}>
                                        <span style={stylesText}><p style={stylesP}>A receber</p></span>
                                        <span style={{...stylesText}} onClick={() => handleOpenNovaReceita()}>
                                            <AddIcon sx={{fontSize: "18px", color: YELLOW_BG_UX}} />
                                            <p style={stylesP}>Nova Receita</p>
                                        </span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container sx={{display: "flex", gap: 2}}>
                                            <Grid item xs>
                                                <Paper
                                                    onClick={() => goToExtrato("contas_receber", "vencidos")}
                                                    sx={{
                                                        ...stylesCard,
                                                        "&:hover": {cursor: "pointer", outline: "1px solid"}
                                                    }}
                                                >
                                                    <span><p style={{...stylesP, color: DASH_KPI_POSITIVE_COLOR, fontWeight: "bold", fontSize: 20}}>{format(valueVencidosReceita)}</p></span>
                                                    <span><p style={{...stylesP, fontWeight: "500"}}>Vencidos (R$)</p></span>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs>
                                                <Paper
                                                    onClick={() => goToExtrato("contas_receber", "vencem_hoje")}
                                                    sx={{
                                                        ...stylesCard,
                                                        "&:hover": {cursor: "pointer", outline: "1px solid"}
                                                    }}
                                                >
                                                    <span><p style={{...stylesP, color: DASH_KPI_POSITIVE_COLOR, fontWeight: "bold", fontSize: 20}}>{format(valueVencemHojeReceita)}</p></span>
                                                    <span><p style={{...stylesP, fontWeight: "500"}}>Vencem hoje (R$)</p></span>
                                                    <span><p style={{...stylesP, color: temaEscuro && BORDER_BUTTON, fontSize: "13px"}}>Restante do mês: R$ {format(valueRestanteMesReceita)}</p></span>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider orientation="vertical" sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}}/>
                            </Grid>
                            <Grid item xs>
                                <Grid container sx={{display: "flex", gap: 1.5}}>
                                    <Grid item xs={12} sx={{display: "flex", justifyContent: "space-between"}}>
                                        <span style={stylesText}><p style={stylesP}>A pagar</p></span>
                                        <span style={{...stylesText}} onClick={() => handleOpenNovaDespesa()}>
                                            <AddIcon sx={{fontSize: "18px", color: YELLOW_BG_UX}} />
                                            <p style={stylesP}>Nova Despesa</p>
                                        </span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container sx={{display: "flex", gap: 2}}>
                                            <Grid item xs>
                                                <Paper
                                                    onClick={() => goToExtrato("contas_pagar", "vencidos")}
                                                    sx={{
                                                        ...stylesCard,
                                                        "&:hover": {cursor: "pointer", outline: "1px solid"}
                                                    }}
                                                >
                                                    <span><p style={{...stylesP, color: RED_INFO, fontWeight: "bold", fontSize: 20}}>{format(valueVencidosDespesa)}</p></span>
                                                    <span><p style={{...stylesP, fontWeight: "500"}}>Vencidos (R$)</p></span>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs>
                                                <Paper
                                                    onClick={() => goToExtrato("contas_pagar", "vencem_hoje")}
                                                    sx={{
                                                        ...stylesCard,
                                                        "&:hover": {cursor: "pointer", outline: "1px solid"}
                                                    }}
                                                >
                                                    <span><p style={{...stylesP, color: RED_INFO, fontWeight: "bold", fontSize: 20}}>{format(valueVencemHojeDespesa)}</p></span>
                                                    <span><p style={{...stylesP, fontWeight: "500"}}>Vencem hoje (R$)</p></span>
                                                    <span><p style={{...stylesP, color: temaEscuro && BORDER_BUTTON, fontSize: "13px"}}>Restante do mês: R$ {format(valueRestanteMesDespesa)}</p></span>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                )}
            </Paper>
        </div>
    );
};

export default Dashboard;