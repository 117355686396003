import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import {
    paperStyle,
    PAPER_PADDING_THEME,
    BORDER_BUTTON,
    GRAY_BORDER_UX,
    GRAY_BG_UX,
    textCompleteStyleBlack,
    WHITE_THEME_BLACK,
    GRAY_STRONG_UX,
    BLUE_THEME,
    muiRootStyle,
    BORDER_PAPER,
    BORDER_BLACK_THEME_CARD
} from "../../shared/utils";
import GrayButton from "../../components/Buttons/GrayButton";
import api from "../../axios";
import Paper from "@mui/material/Paper";
import Header from "../../components/Titles/Header";
import YellowButton from "../../components/Buttons/YellowButton";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import {showSnackMessage} from "../../actions/SnackActions";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import FilterWithTag from "../../components/Filter/FilterWithTag";


function UploadCertificado() {
    const [selectedFile, setSelectedFile] = useState();
    const [selectedKeyFile, setSelectedKeyFile] = useState();
    const [isSelected, setIsSelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bancos, setBancos] = useState([]);
    const [bancosSelected, setBancosSelected] = useState([]);
    const [certificadoPassword, setCertificadoPassword] = useState("");
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const hiddenFileInput = React.useRef(null);    

    const nav = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        if (estabelecimentoId !== 0) {
            getBancos();
        }
    }, [estabelecimentoId]);

    const getBancos = () => {
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            type: "banco"
        };

        api.GetCertificados(dataRequest).then((response) => {
            setBancos(response.data.bancos);
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const changeHandler = (event) => {                
        let file = event.target.files[0];
        let file2 = event.target.files[1];
        
        if (file != null) {
            if (!bancosSelected.find((banco) => banco === "Inter") && file.type === "application/x-pkcs12" || file.type === "application/pkcs12") {
                setSelectedFile(file);
                setIsSelected(true);
            } 
            else if (bancosSelected.find((banco) => banco === "Inter") && (file.type === "application/pkix-cert" || file2?.type === "application/pkix-cert") && (file.type === "application/vnd.apple.keynote" || file2?.type === "application/vnd.apple.keynote")) {                
                setSelectedFile(file.type === "application/pkix-cert" ? file : file2);
                setSelectedKeyFile(file2.type === "application/vnd.apple.keynote" ? file2 : file);
                setIsSelected(true);
            }
            else {
                let message = bancosSelected.find((banco) => banco === "Inter") ? "Insira o certificado e a chave" : "Tipo de certificado inválido";
                dispatch(showSnackMessage({ message: message, severity: "error" }));
            }
        }
    };

    const handleSubmission = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("estabelecimento_id", estabelecimentoId);
        formData.append("bancos_selected", JSON.stringify(bancosSelected));
        formData.append("cert_password", certificadoPassword);
        formData.append("file_name", selectedFile.name);
        formData.append("selected_file", selectedFile);
        formData.append("key_file_name", selectedKeyFile?.name);
        formData.append("selected_key_file", selectedKeyFile);

        api.SendUploadCertificado(formData).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Seu certificado foi carregado com sucesso!" }));
            setSelectedFile(null);
            setIsSelected(false);
            setCertificadoPassword("");
            setBancosSelected(null);
            nav("/certificados");
        }).catch((error) => {
            setLoading(false);            
            if (error.response.status === 403 && error.response.data) {
                dispatch(showSnackMessage({ message: error.response.data, severity: "error" }));
            }
            else {
                dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
            }
        });
    };    

    return (
        <div className="main">
            <Header subTitle="Certificados" title="Novo Certificado" previousPage="/certificados"/>
            <Paper 
                data-testid="upload-certificados" 
                sx={{
                    ...paperStyle,
                    backgroundColor: temaEscuro ? PAPER_PADDING_THEME : GRAY_BG_UX,
                    backgroundImage: temaEscuro && "none",
                    border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`
                }}
            >
                <Grid container spacing={2} sx={{display: "flex", flexDirection: "column"}}>
                    <Grid item sx={{width: "400px"}}>
                        <FilterWithTag
                            testId="banco-input"
                            widthSetting="400px"
                            options={bancos}
                            setOptions={setBancosSelected}
                            placeholderProp="Bancos"
                            optionFilter
                        />
                    </Grid>
                    <Grid item sx={{width: "400px"}}>
                        {
                            !bancosSelected.find((banco) => banco === "Inter") && (
                                <TextField
                                    data-testid="senha-certificado"
                                    fullWidth
                                    size="small"
                                    value={certificadoPassword}
                                    onChange={(e) => setCertificadoPassword(e.target.value)}
                                    label='Senha do certificado'
                                    sx={{...(temaEscuro ? textCompleteStyleBlack : muiRootStyle), backgroundColor: temaEscuro && BLUE_THEME}}
                                    InputLabelProps={{
                                        style: {
                                            fontFamily: "Inter, sans-serif",
                                            color: temaEscuro ? BORDER_BUTTON : "#4A5568",
                                            fontWeight: "500"
                                        }
                                    }}
                                />
                            )
                        }
                    </Grid>
                    <Grid item sx={{width: "400px"}}>
                        <GrayButton
                            testId="button-anexar-certificado"
                            title={bancosSelected.find((banco) => banco === "Inter") ? "Anexar certificados .crt e .key" : "Anexar certificado .pfx"}
                            onClick={handleClick}
                            startIcon={<AttachFileOutlinedIcon/>}
                        />
                    </Grid>
                    <Grid item sx={{width: "400px"}}>
                        <input type="file"
                            accept={bancosSelected.find((banco) => banco === "Inter") ? ".crt, .key" : ".pfx"}
                            ref={hiddenFileInput}
                            onChange={changeHandler}
                            style={{display: "none", ml: 1}}
                            multiple={bancosSelected.find((banco) => banco === "Inter")}
                        />
                        {
                            isSelected &&
                                <Grid style={{...styles.styleFieldImport, color: temaEscuro && WHITE_THEME_BLACK}}>
                                {
                                  bancosSelected.find((banco) => banco === "Inter") ? (
                                    <span>Nome dos arquivos: {selectedFile?.name} e {selectedKeyFile?.name}</span>
                                  ) : (
                                    <span>Nome do arquivo: {selectedFile?.name}</span>
                                  )
                                }
                                </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid container sx={{display: "flex", justifyContent: "end", gap: 2, marginTop: 7}}>
                    <Grid item>
                        <GrayButton
                            onClick={() => nav("/certificados")}
                            title="Cancelar"
                        />
                    </Grid>
                    <Grid item>
                        {
                            loading ? (
                                <LoadingButton variant="outlined" loading >
                                    Enviar Certificado
                                </LoadingButton>
                            ) : (
                                <YellowButton
                                    onClick={handleSubmission}
                                    testId="button-enviar-certificado"
                                    title="Adicionar Certificado"
                                />
                            )
                        }
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

const styles = {
    styleFieldImport: {
        color : GRAY_STRONG_UX
    },

    formControl: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: GRAY_BORDER_UX
            },
        }
    },
};

export default UploadCertificado;