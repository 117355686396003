import React, {useEffect, useState} from "react";
import Skeleton from "@mui/material/Skeleton";
import Header from "../../components/Titles/Header";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import {changeStore} from "../../actions/StoreActions";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
    BLUE_THEME,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER, BORDER_TABLE, formatterPercentage, formatterThousand, GRAY_BG_UX, GRAY_LABEL_UX,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle, styleText, WHITE_ESCRITA_THEME, WHITE_THEME_BLACK, YELLOW_BG_UX, WHITE_TABLE, ROW_MAIN, getCurrentDate,
    GRAY_PDF,
    RED_INFO,
    format,
    TITLE_REUNIAO,
    GRAY_LABEL_UX_THEME,
    BLACK_LABEL_UX,
    BLACK_TABLE
} from "../../shared/utils";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputSearch from "../../components/Input/InputSearch";
import FilterSingleOption from "../../components/Filter/FilterSingleOption";
import FilterDayMonthYear from "../../components/Filter/FilterDayMonthYear";
import Checkbox from "@mui/material/Checkbox";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import InputNumber from "../../components/Input/InputNumber";
import api from "../../axios";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import YellowButton from "../../components/Buttons/YellowButton";
import GrayButton from "../../components/Buttons/GrayButton";
import ParcelasModal from "../../components/Modal/ParcelasModal";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import moment from "moment";
import CreateOptionModal from "../../components/Modal/CreateOptionModal";
import {showSnackMessage} from "../../actions/SnackActions";
import Divider from "@mui/material/Divider";
import InputCurrency from "../../components/Input/InputCurrency";
import AnexosReceita from "../../components/Tabs/AnexosReceita";
import SaveOptions from "../../components/Buttons/SaveOptions";


const GerarCompra = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [locationId, setLocationId] = useState(0);
    const [fornecedoresOptions, setFornecedoresOptions] = useState([]);
    const [produtoOptions, setProdutoOptions] = useState([]);
    const [categoriaOptions, setCategoriaOptions] = useState([]);
    const [centroCustoOptions, setCentroCustoOptions] = useState([]);
    const [pago, setPago] = useState(false);
    const [agendado, setAgendado] = useState(false);
    const [selectedTab, setSelectedTab] = useState("observacoes");
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);

    const formaRecorrenciaOptions = ["Ano/anos", "Mês/meses", "Semana/semanas", "Dia/dias"];
    const recorrenciaTerminoOptions = ["Em um período específico", "Após alguns ocorrências"];

    const parcelaOptions = ["1", "2", "3", "4", "5", "12"];
    const formaPagamentoOptions = [
        "Boleto via outros bancos",
        "Cashback",
        "Cheque",
        "Cartão de crédito via outros bancos",
        "Cartão de débito via outros bancos",
        "Carteira digital",
        "Crédito da loja",
        "Crédito virtual",
        "Débito Automático",
        "Depósito bancário",
        "Dinheiro",
        "Outros",
        "Pix",
        "Programa de fideliade",
        "Sem pagamento",
        "Transferência bancária",
        "Vale-alimentação",
        "Vale-combustível",
        "Vale-presente",
        "Vale-refeição",
    ];
	const currentMonthDate = moment(getCurrentDate()).toDate();

    const [recorrenciaVencimentoOptions, setRecorrenciaVencimentoOptions] = useState(["Segunda-feira", "Terça-feira", "Quarta-feria", "Quinta-feira", "Sexta-feira", "Sábado", "Domingo"]);

    const [selectedFornecedor, setSelectedFornecedor] = useState(null);
    const [codigoReferencia, setCodigoReferencia] = useState("");
    const [selectedDataCompra, setSelectedDataCompra] = useState(currentMonthDate);
    const [repetirCompra, setRepetirCompra] = useState(false);
    const [cadastrarProduto, setCadastrarProduto] = useState(false);
    const [qtdRecorrenciaCompra, setQtdRecorrenciaCompra] = useState(1);
    const [formaRecorrenciaCompra, setFormaRecorrenciaCompra] = useState("Mês/meses");
    const [selectedDataCompraRecorrencia, setSelectedDataCompraRecorrencia] = useState(currentMonthDate);
    const [selectedRecorrenciaVencimento, setSelectedRecorrenciaVencimento] = useState(null);
    const [selectedDataRecorrenciaTermino, setSelectedDataRecorrenciaTermino] = useState(currentMonthDate);
    const [selectedCategoria, setSelectedCategoria] = useState(null);
    const [selectedCentroCusto, setSelectedCentroCusto] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [valorIsPago, setValorIsPago] = useState(false);
    const [juros, setJuros] = useState(0);
    const [multa, setMulta] = useState(0);
    const [numeroRecorrenciaCompra, setNumeroRecorrenciaCompra] = useState(1);
    const [desconto, setDesconto] = useState(0);
    const [descontoValue, setDescontoValue] = useState(null);
    const [freteValue, setFreteValue] = useState(null);
    const [totalDiscountedValue, setTotalDiscountedValue] = useState(0);
    const [selectedParcela, setSelectedParcela] = useState(null);
    const [selectedFormaPagamento, setSelectedFormaPagamento] = useState(null);
    const [parcelas, setParcelas] = useState([]);
    const [parcelaDataVencimento, setParcelaDataVencimento] = useState(currentMonthDate);
    const [selectedDataPagamento, setSelectedDataPagamento] = useState(currentMonthDate);
    const [descricao, setDescricao] = useState("");
    const [selectedRecorrenciaTermino, setSelectedRecorrenciaTermino] = useState("Em um período específico");
    const [items, setItems] = useState([
        {
            produto_servico: "",
            detalhes_item: "",
            quantidade: 1,
            valor_unitario: null,
            total: null
        },
    ]);
    const [observacoes, setObservacoes] = useState("");

    const [selectedReaisPercent, setSelectedReaisPercent] = useState("reais");
    const [openParcelasModal, setOpenParcelasModal] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [optionCreateModal, setOptionCreateModal] = useState("");
    const [inputError, setInputError] = useState(false);
    const [intervaloParcelas, setIntervaloParcelas] = useState(0);
    const [contasOptions, setContasOptions] = useState([]);
    const [selectedConta, setSelectedConta] = useState(null);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);    

    useEffect(() => {
        if (locationId) {
            const query = new URLSearchParams(location.search);
            setLocationId(parseInt(query.get("estabelecimento_id")));
            if (locationId !== 0 && locationId != null) {
                dispatch(changeStore(locationId));
            }
        }
    }, [locationId]);

    useEffect(() => {
        if (formaRecorrenciaCompra === "Semana/semanas") {
            setRecorrenciaVencimentoOptions(["Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado", "Domingo"]);
        } else if (formaRecorrenciaCompra === "Mês/meses") {
            const options = [];
            for (let i = 1; i <= 30; i++) {
                options.push(`${i} Dia útil do mês`);
            }
            options.push("Último dia útil");
            setRecorrenciaVencimentoOptions(options);
        } else if (formaRecorrenciaCompra === "Ano/anos" || formaRecorrenciaCompra === "Dia/dias") {
            setSelectedRecorrenciaVencimento("1 Dia útil do mês");
        }
    }, [formaRecorrenciaCompra]);

    useEffect(() => {
        calcTotalValue();
    }, [items]);

    useEffect(() => {
        calcTotalDiscountedValue();
    }, [totalRows, descontoValue, freteValue, selectedReaisPercent]);

    useEffect(() => {
        if (selectedParcela) {            
            handleParcelas();
        }
    }, [selectedParcela, totalDiscountedValue, intervaloParcelas]);

    useEffect(() => {
        getInputsOptions();
    }, [estabelecimentoId]);
    
    const styles = {
        selectedButton: {
            backgroundColor: YELLOW_BG_UX,
            color: BLUE_THEME,
            "&.MuiButtonBase-root:hover": {
                bgcolor: YELLOW_BG_UX,
            }
        },
        unselectedButton: {
            backgroundColor: temaEscuro ? BLUE_THEME : BORDER_PAPER,
            color: temaEscuro ? WHITE_ESCRITA_THEME : BLUE_THEME,
            "&.MuiButtonBase-root:hover": {
                bgcolor: TITLE_REUNIAO,
            }
        }
    };

    const formatDateIfNeeded = (date) => {
        return typeof(date) !== "object" ? date : moment(date).format("DD-MM-YYYY");
    };

    const getInputsOptions = () => {
        if (estabelecimentoId !== 0) {
            setLoading(true);
            let dataRequest = {
                estabelecimento_id: estabelecimentoId,
                papel_pessoa: "Fornecedor",
            };
            api.GetFornecedores(dataRequest).then(response => {
                setFornecedoresOptions(response.data);
            });

            api.GetCategorias({...dataRequest, "tipo": "Despesa"}).then(response => {
                setCategoriaOptions(response.data);
            });
            api.GetProdutosServicos(dataRequest).then(response => {
                setProdutoOptions(response.data);
            });
            api.GetCentroCustos(dataRequest).then(response => {
                setCentroCustoOptions(response.data);
            });
            api.RetrieveContas(dataRequest).then(response => {
                setContasOptions(response.data);
            });
            setLoading(false);
        }
    };

    const postSaveCompra = (redirect = true) => {
        if (!selectedFornecedor || !selectedCategoria || !selectedDataCompra || (repetirCompra ? false : !selectedParcela || !selectedFormaPagamento)) {
            setInputError(true);
            return;
        }
        for (let parcela of parcelas) {
            parcela.data_vencimento = moment(parcela.data_vencimento).format("DD-MM-YYYY");
        }
        let formData = new FormData();
        formData.append("estabelecimento_id", estabelecimentoId);
        formData.append("fornecedor_id", selectedFornecedor.value);
        formData.append("categoria", selectedCategoria.value);
        formData.append("centro_custo_id", selectedCentroCusto.value);
        formData.append("data_compra", formatDateIfNeeded(selectedDataCompra));
        formData.append("reais_percent", selectedReaisPercent);
        formData.append("desconto", parseFloat(descontoValue) || 0);
        formData.append("frete", parseFloat(freteValue) || 0);
        formData.append("total", (totalDiscountedValue - juros - multa) + desconto);
        formData.append("forma_pagamento", selectedFormaPagamento);
        formData.append("condicao_pagamento", selectedParcela);
        formData.append("vencimento", formatDateIfNeeded(parcelaDataVencimento));
        formData.append("parcelas", JSON.stringify(parcelas));
        formData.append("descricao", descricao);
        formData.append("agendado", agendado);
        formData.append("is_pago", pago);
        formData.append("juros", juros);
        formData.append("multa", multa);
        if (codigoReferencia) {
            formData.append("codigo_referencia", codigoReferencia);
        }
        formData.append("observacoes", observacoes);
        if (selectedConta != null) {
            formData.append("conta_pagamento", selectedConta.value);
        }
        if (selectedCentroCusto) {
            formData.append("centro_custo", selectedCentroCusto.value);
        }
        if (repetirCompra) {
            const recorrenciaData = {
                qtd_recorrencia: qtdRecorrenciaCompra,
                forma_recorrencia: formaRecorrenciaCompra,
                data_compra_recorrencia: moment(selectedDataCompraRecorrencia).format(),
                vencimento_recorrencia: selectedRecorrenciaVencimento,
            };
            if (selectedRecorrenciaTermino === "Após alguns ocorrências") {
                recorrenciaData.numero_recorrencia = numeroRecorrenciaCompra;
            } else if (selectedRecorrenciaTermino === "Em um período específico") {
                recorrenciaData.data_termino_recorrencia = formatDateIfNeeded(selectedDataRecorrenciaTermino);
            }
            formData.append("recorrencia", JSON.stringify(recorrenciaData));
        }
        if (selectedCentroCusto) {
            formData.append("centro_custo", selectedCentroCusto.value);
        }
        if (cadastrarProduto) {
            formData.append("items", items);
            formData.append("reais_percent", selectedReaisPercent);
            formData.append("desconto", parseFloat(descontoValue) || 0);
            formData.append("frete", parseFloat(freteValue) || 0);
        }
        if (anexos[0].arquivo != null){
            formData.append("quantidade_anexo", anexos.length);
            anexos.forEach((anexo, index) => {
                formData.append(`anexos[${index}][forma_anexo]`, anexo.forma_anexo);
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(`anexos[${index}][tipo_anexo]`, anexo.tipo_anexo);
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }
        api.PostGerarCompra(formData).then(() => {
            dispatch(showSnackMessage({message: "Compra gerada com sucesso!", severity: "success"}));
            if (redirect) {
                navigate("/extrato_movimentacoes");
            }
            else {
                handleParcelas();
                setSelectedParcela(selectedParcela);
            }
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const handleDayMonthYear = (ano, mes, dia, setState) => {
        setState(`${dia}-${mes}-${ano}`);
    };

    const handleRepetirCompra = (event) => {
        setRepetirCompra(event.target.checked);
    };

    const handleParcelas = (skipIndex = false, skipRow = {}, alteredField = "") => {
        let _parcelas = [];
        let valorTotal = 0;
        let percentTotal = 0;
        let _totalValue = totalDiscountedValue;
        let parcelasQuantidade = selectedParcela;
        for (let i = 0; i < selectedParcela; i++) {
            if ((skipIndex || typeof skipIndex === "number") && i === skipIndex) {
                if (alteredField === "valor") {
                    if (skipRow.valor >= totalDiscountedValue || skipRow.valor === 0) {
                        skipRow.error = true;
                        return;
                    }
                    _totalValue = totalDiscountedValue - skipRow.valor;
                    skipRow.percentual = parseFloat(formatterPercentage((skipRow.valor * 100) / totalDiscountedValue, 2));
                    percentTotal += skipRow.percentual;
                    skipRow.error = false;
                } else {
                    if (skipRow.percentual >= 100 || skipRow.percentual === 0) {
                        skipRow.error = true;
                        return;
                    }
                    percentTotal += parseFloat(skipRow.percentual);
                    skipRow.valor = parseFloat(formatterThousand(totalDiscountedValue * (skipRow.percentual / 100), true));
                    _totalValue -= skipRow.valor;
                    skipRow.error = false;
                }
                parcelasQuantidade = selectedParcela - 1;
                _parcelas.push(skipRow);
                continue;
            }
            let valorParcela = 0;
            let percentParcela = 0;
            if (i === selectedParcela - 1) {
                valorParcela = formatterThousand(_totalValue - valorTotal, true);
                percentParcela = formatterPercentage(100 - percentTotal, 2);
            } else {
                valorParcela = formatterThousand(_totalValue / parseFloat(parcelasQuantidade), true);
                percentParcela = formatterPercentage((valorParcela * 100) / totalDiscountedValue, 2);
            }
            // Calculando a data de vencimento com intervalo de dias
            let parcelaData = null;
            if (intervaloParcelas === 0 || intervaloParcelas === "") {
                parcelaData = moment(parcelaDataVencimento || getCurrentDate()).add(i, "months");
            } else {
                parcelaData = moment(parcelaDataVencimento || getCurrentDate()).add(i * intervaloParcelas, "days");
            }
            _parcelas.push({
                "valor": parseFloat(valorParcela),
                "percentual": parseFloat(percentParcela),
                "data_vencimento": parcelaData.toDate(),
                "descricao": ""
            });
            valorTotal += parseFloat(valorParcela);
            percentTotal += parseFloat(percentParcela);
        }
        setParcelas(_parcelas);
    };

    const handleRemoveRow = (idx) => {
        items.splice(idx, 1);
        setItems([...items]);
    };

    const calcTotalValue = () => {
        let total = 0;
        items.map((item) => {
            total += item.total;
        });
        setTotalRows(total);
    };

    const calcTotalDiscountedValue = () => {
        if (descontoValue || freteValue) {
            if (selectedReaisPercent === "percent") {
                setTotalDiscountedValue(totalRows - (totalRows * (parseFloat(descontoValue || 0) / 100)) + parseFloat(freteValue || 0));
                setValorIsPago(totalRows - (totalRows * (parseFloat(descontoValue || 0) / 100)) + parseFloat(freteValue || 0));
            } else {
                setTotalDiscountedValue(totalRows - parseFloat(descontoValue || 0) + parseFloat(freteValue || 0));
                setValorIsPago(totalRows - parseFloat(descontoValue || 0) + parseFloat(freteValue || 0));
            }
        } else {
            setTotalDiscountedValue(totalRows);
            setValorIsPago(totalRows);
        }
    };

    const handleNavExtrato = () => {
        navigate("/extrato_movimentacoes");
    };

    const cleanCompraInputs = () => {
        setSelectedFornecedor(null);
        setCodigoReferencia("");
        setSelectedDataCompra(currentMonthDate);
        setRepetirCompra(false);
        setQtdRecorrenciaCompra(null);
        setFormaRecorrenciaCompra(null);
        setSelectedDataCompraRecorrencia(currentMonthDate);
        setSelectedRecorrenciaVencimento(null);
        setSelectedDataRecorrenciaTermino(currentMonthDate);
        setSelectedCategoria(null);
        setSelectedCentroCusto(null);
        setItems([
            {
                produto_servico: "",
                detalhes_item: "",
                quantidade: 1,
                valor_unitario: null,
                total: null
            },
        ]);
        setObservacoes("");
        setDescontoValue(null);
        setFreteValue(null);
        setTotalDiscountedValue(0);
        setSelectedParcela(null);
        setSelectedFormaPagamento(null);
        setParcelas([]);
        setParcelaDataVencimento(currentMonthDate);
        setSelectedDataPagamento(currentMonthDate);
        setDescricao("");
        setContasOptions([]);
    };

    return (
        <React.Fragment>
            <div className="main" data-testid="gerar-compra">
                <ParcelasModal
                    openModal={openParcelasModal}
                    setOpenModal={setOpenParcelasModal}
                    formaPagamento={selectedFormaPagamento}
                    valorPagamento={totalDiscountedValue}
                    condicaoPagamento={selectedParcela}
                    parcelas={parcelas}
                    setParcelas={setParcelas}
                    handleParcelas={handleParcelas}
                    intervaloParcelas={intervaloParcelas}
                />
                <CreateOptionModal
                    openModal={openCreateModal}
                    setOpenModal={setOpenCreateModal}
                    option={optionCreateModal}
                    getInputsOptions={getInputsOptions}
                />
                {
                    loading && (
                        <Skeleton sx={{mb: "14px"}} variant="rectangular" width={"17%"} height={"17px"}/>
                    )
                } 
                <Header subTitle="EXM - Extrato de Movimentações" title="Gerar Compra" previousPage="/extrato_movimentacoes"/> 
                <React.Fragment>
                    {
                        loading ? (
                            <Skeleton variant="rectangular" width={"100%"} height={"80vh"}/>
                        ) : (
                            <React.Fragment>
                                <Paper sx={{
                                    ...paperStyle,
                                    backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                                    backgroundImage: temaEscuro && "none",
                                    border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`
                                }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <span>
                                                <p className="title-container-p">Informações da compra</p>
                                            </span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} /> 
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item sx={{mr: "2rem"}}>
                                                    <FilterSingleOption
                                                        filterOptions={fornecedoresOptions}
                                                        selectedOption={selectedFornecedor}
                                                        setSelectedOption={setSelectedFornecedor}
                                                        placeholder="Fornecedor *"
                                                        canCreate
                                                        setOpenCreateModal={setOpenCreateModal}
                                                        setOptionCreateModal={setOptionCreateModal}
                                                        inputError={inputError}
                                                    />
                                                </Grid>
                                                <Grid item sx={{mr: "2rem"}}>
                                                    <InputSearch
                                                        functionProps={() => {}}
                                                        setSearchValue={setCodigoReferencia}
                                                        searchValue={codigoReferencia}
                                                        className="input-text"
                                                        label={"Código de referência"}
                                                    />
                                                </Grid>
                                                <Grid item sx={{mr: "2rem"}}>
                                                    <FilterSingleOption
                                                        filterOptions={categoriaOptions}
                                                        selectedOption={selectedCategoria}
                                                        setSelectedOption={setSelectedCategoria}
                                                        placeholder="Categoria de despesa *"
                                                        canCreate
                                                        setOpenCreateModal={setOpenCreateModal}
                                                        setOptionCreateModal={setOptionCreateModal}
                                                        inputError={inputError}
                                                    />
                                                </Grid>
                                                <Grid item sx={{mr: "2rem"}}>
                                                    <FilterSingleOption
                                                        filterOptions={centroCustoOptions}
                                                        selectedOption={selectedCentroCusto}
                                                        setSelectedOption={setSelectedCentroCusto}
                                                        placeholder={"Centro de custo"}
                                                        canCreate
                                                        setOpenCreateModal={setOpenCreateModal}
                                                        setOptionCreateModal={setOptionCreateModal}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FilterDayMonthYear
                                                        gerarCompra
                                                        style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                                        handleSubmit={handleDayMonthYear}
                                                        value={selectedDataCompra}
                                                        setState={setSelectedDataCompra}
                                                        size={"small"}
                                                        width={"250px"}
                                                        iconPosition={"end"}
                                                        label={"Data da compra"}
                                                        inputError={inputError}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Checkbox
                                                        checked={repetirCompra}
                                                        icon={<CircleOutlinedIcon/>}
                                                        checkedIcon={<CircleIcon/>}
                                                        onChange={handleRepetirCompra}
                                                    />
                                                    <a style={{
                                                        ...styleText,
                                                        color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                                    }}>Repetir compra</a>
                                                </Grid>
                                                <Grid item>
                                                    <Checkbox
                                                        checked={cadastrarProduto}
                                                        icon={<CircleOutlinedIcon/>}
                                                        checkedIcon={<CircleIcon/>}
                                                        onChange={(e) => setCadastrarProduto(e.target.checked)}
                                                    />
                                                    <a style={{
                                                        ...styleText,
                                                        color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                                    }}>Cadastrar produto/serviço</a>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {
                                            repetirCompra && (
                                                <React.Fragment>
                                                    <Grid item xs={12}>
                                                        <a>Configurações da recorrência</a>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{marginBottom: 2}}>
                                                        <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} /> 
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <InputNumber
                                                                    value={qtdRecorrenciaCompra}
                                                                    setValue={setQtdRecorrenciaCompra}
                                                                    label="Repetir compra a cada"
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <FilterSingleOption
                                                                    filterOptions={formaRecorrenciaOptions}
                                                                    selectedOption={formaRecorrenciaCompra}
                                                                    setSelectedOption={setFormaRecorrenciaCompra}
                                                                    placeholder="Forma de recorrência"
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <FilterDayMonthYear
                                                                    style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                                                    handleSubmit={handleDayMonthYear}
                                                                    value={selectedDataCompraRecorrencia}
                                                                    setState={setSelectedDataCompraRecorrencia}
                                                                    disableMinDate
                                                                    size={"small"}
                                                                    width={"250px"}
                                                                    iconPosition={"end"}
                                                                    label={"Data de criação da compra"}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <FilterSingleOption
                                                                    filterOptions={recorrenciaVencimentoOptions}
                                                                    selectedOption={selectedRecorrenciaVencimento}
                                                                    setSelectedOption={setSelectedRecorrenciaVencimento}
                                                                    placeholder={"Vencimento"}
                                                                    disabled={["Ano/anos", "Dia/dias"].includes(formaRecorrenciaCompra) ? true : false}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <FilterSingleOption
                                                                    filterOptions={recorrenciaTerminoOptions}
                                                                    selectedOption={selectedRecorrenciaTermino}
                                                                    setSelectedOption={setSelectedRecorrenciaTermino}
                                                                    placeholder={"Término da recorrência"}
                                                                />
                                                            </Grid>
                                                            {
                                                                selectedRecorrenciaTermino === "Em um período específico" && (
                                                                    <Grid item>
                                                                        <FilterDayMonthYear
                                                                            style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                                                            handleSubmit={handleDayMonthYear}
                                                                            value={selectedDataRecorrenciaTermino}
                                                                            setState={setSelectedDataRecorrenciaTermino}
                                                                            size={"small"}
                                                                            width={"250px"}
                                                                            iconPosition={"end"}
                                                                            label={"Data de término"}
                                                                        />
                                                                    </Grid>
                                                                )
                                                            }
                                                            {
                                                                selectedRecorrenciaTermino === "Após alguns ocorrências" && (
                                                                    <Grid item>
                                                                        <InputNumber
                                                                            value={numeroRecorrenciaCompra}
                                                                            setValue={setNumeroRecorrenciaCompra}
                                                                            label="Número de ocorrências"
                                                                        />
                                                                    </Grid>
                                                                )
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            )
                                        }
                                        {cadastrarProduto && (
                                            <React.Fragment>
                                                <Grid item xs={12} sx={{marginTop: 2}}>
                                                    <span>
                                                        <p className="title-container-p">Itens da compra</p>
                                                    </span>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TableContainer className={"table-container"}>
                                                        <Table>
                                                            <TableHead style={{backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF}}>
                                                                <TableRow>
                                                                    <TableCell
                                                                        align={"center"}
                                                                        style={{
                                                                        ...{zIndex: 3},
                                                                        ...{fontWeight: "bold"},
                                                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                                        ...{color: temaEscuro && LINE_TABLE},
                                                                        ...{padding: 5}}}
                                                                    >
                                                                        Produto/Serviço *
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align={"center"}
                                                                        style={{
                                                                        ...{zIndex: 3},
                                                                        ...{fontWeight: "bold"},
                                                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                                        ...{color: temaEscuro && LINE_TABLE},
                                                                        ...{padding: 5}}}
                                                                    >
                                                                        Detalhes do item
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align={"center"}
                                                                        style={{
                                                                        ...{zIndex: 3},
                                                                        ...{fontWeight: "bold"},
                                                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                                        ...{color: temaEscuro && LINE_TABLE},
                                                                        ...{padding: 5}}}
                                                                    >
                                                                        Quantidade *
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align={"center"}
                                                                        style={{
                                                                        ...{zIndex: 3},
                                                                        ...{fontWeight: "bold"},
                                                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                                        ...{color: temaEscuro && LINE_TABLE},
                                                                        ...{padding: 5}}}
                                                                    >
                                                                        Valor unitário *
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align={"center"}
                                                                        style={{
                                                                        ...{zIndex: 3},
                                                                        ...{fontWeight: "bold"},
                                                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                                        ...{color: temaEscuro && LINE_TABLE},
                                                                        ...{padding: 5}}}
                                                                    >
                                                                        Total *
                                                                    </TableCell>
                                                                    {
                                                                        items.length > 1 && (
                                                                            <TableCell/>
                                                                        )
                                                                    }
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    items.map((item, idx) => {
                                                                        return (
                                                                            <TableRow key={idx} style={{backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE}}>
                                                                                <TableCell align={"center"}>
                                                                                    <FilterSingleOption
                                                                                        filterOptions={produtoOptions}
                                                                                        selectedOption={item.produto_servico}
                                                                                        setSelectedOption={(value) => (setItems(items.map((item_aux, idx_aux) => {
                                                                                            if (idx_aux === idx) {
                                                                                                item_aux.produto_servico = value;
                                                                                            }
                                                                                            return item_aux;
                                                                                        })))}
                                                                                        placeholder="Produto/Serviço *"
                                                                                        canCreate
                                                                                        setOpenCreateModal={setOpenCreateModal}
                                                                                        setOptionCreateModal={setOptionCreateModal}
                                                                                        inputError={inputError}
                                                                                        justifyContent={"center"}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align={"center"}>
                                                                                    <InputSearch
                                                                                        functionProps={() => {}}
                                                                                        setSearchValue={(value) => (setItems(items.map((item_aux, idx_aux) => {
                                                                                            if (idx_aux === idx) {
                                                                                                item_aux.detalhes_item = value;
                                                                                            }
                                                                                            return item_aux;
                                                                                        })))}
                                                                                        searchValue={item.detalhes_item}
                                                                                        className="input-text"
                                                                                        padding={0.5}
                                                                                        width={"200px"}
                                                                                        label={"Detalhes"}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align={"center"}>
                                                                                    <InputNumber
                                                                                        value={item.quantidade}
                                                                                        setValue={(value) => (setItems(items.map((item_aux, idx_aux) => {
                                                                                            if (idx_aux === idx) {
                                                                                                item_aux.quantidade = parseFloat(value);
                                                                                            }
                                                                                            if (item.valor_unitario !== 0) {
                                                                                                item_aux.total = item_aux.valor_unitario * item_aux.quantidade;
                                                                                            }
                                                                                            return item_aux;
                                                                                        })))}
                                                                                        width={"100px"}
                                                                                        padding={0.5}
                                                                                        error={inputError}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align={"center"}>
                                                                                    <InputCurrency
                                                                                        valor={item.valor_unitario}
                                                                                        setValor={(value) => (setItems(items.map((item_aux, idx_aux) => {
                                                                                            if (idx_aux === idx) {
                                                                                                item_aux.valor_unitario = parseFloat(value);
                                                                                            }
                                                                                            if (item.quantidade !== 0) {
                                                                                                item_aux.total = item_aux.valor_unitario * item_aux.quantidade;
                                                                                            }
                                                                                            return item_aux;
                                                                                        })))}
                                                                                        width={"125px"}
                                                                                        error={inputError}
                                                                                        ml={"15%"}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align={"center"}>
                                                                                    <InputCurrency
                                                                                        valor={item.total}
                                                                                        setValor={(value) => (setItems(items.map((item_aux, idx_aux) => {
                                                                                            if (idx_aux === idx) {
                                                                                                item_aux.total = parseFloat(value);
                                                                                            }
                                                                                            return item_aux;
                                                                                        })))}
                                                                                        width={"150px"}
                                                                                        padding={0.5}
                                                                                        error={inputError}
                                                                                        ml={"15%"}
                                                                                    />

                                                                                </TableCell>
                                                                                {
                                                                                    items.length > 1 && (
                                                                                        <TableCell
                                                                                            align={"center"}
                                                                                            style={{padding: 0, cursor: "pointer"}}
                                                                                            onClick={() => handleRemoveRow(idx)}
                                                                                        >
                                                                                            <a>X</a>
                                                                                        </TableCell>
                                                                                    )
                                                                                }
                                                                            </TableRow>
                                                                        );
                                                                    })
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <GrayButton
                                                        title={"+ Adicionar nova linha"}
                                                        size={"small"}
                                                        onClick={() => {
                                                            setItems([...items, {
                                                                produto_servico: "",
                                                                detalhes_item: "",
                                                                quantidade: 1,
                                                                valor_unitario: null,
                                                                total: null
                                                            }]);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={5}>
                                                            <Grid container spacing={2}
                                                                    sx={{display: "flex", justifyContent: "end"}}>
                                                                <Grid item>
                                                                    <ButtonGroup variant="contained"
                                                                                    aria-label="Basic button group">
                                                                        <Button
                                                                            variant="contained"
                                                                            sx={selectedReaisPercent === "reais" ? styles.selectedButton : styles.unselectedButton}
                                                                            onClick={() => setSelectedReaisPercent("reais")}
                                                                        >
                                                                            R$
                                                                        </Button>
                                                                        <Button
                                                                            variant="contained"
                                                                            sx={selectedReaisPercent === "percent" ? styles.selectedButton : styles.unselectedButton}
                                                                            onClick={() => setSelectedReaisPercent("percent")}
                                                                        >
                                                                            %
                                                                        </Button>
                                                                    </ButtonGroup>
                                                                </Grid>
                                                                <Grid item>
                                                                    {selectedReaisPercent === "reais" ? (
                                                                        <InputCurrency
                                                                            valor={descontoValue}
                                                                            setValor={setDescontoValue}
                                                                            title={"Desconto"}
                                                                        />
                                                                    ) : (
                                                                        <InputNumber
                                                                            label={"Desconto"}
                                                                            value={descontoValue}
                                                                            setValue={setDescontoValue}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item sx={{margin: 1}}>
                                                            <Divider orientation="vertical" sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} />
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container spacing={2}>
                                                                <Grid item>
                                                                    <InputCurrency
                                                                        valor={freteValue}
                                                                        setValor={setFreteValue}
                                                                        title={"Frete (R$)"}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box sx={{
                                                        ...paperStyle,
                                                        backgroundColor: temaEscuro && BLUE_THEME,
                                                        borderRadius: 2
                                                    }}>
                                                        <Grid container spacing={0.5}>
                                                            <Grid item xs={3}>
                                                                <a>Total da compra</a>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container rowSpacing={1}>
                                                                    <Grid item xs={12}
                                                                        sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>Total dos produtos (R$)</a>
                                                                    </Grid>
                                                                    <Grid item xs={12}
                                                                        sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>{formatterThousand(totalRows)}</a>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item sx={{ml: 3.5}}>
                                                                <b>-</b>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container rowSpacing={1}>
                                                                    <Grid item xs={12} sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>Descontos (R$)</a>
                                                                    </Grid>
                                                                    <Grid item xs={12} sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>
                                                                            {selectedReaisPercent === "reais"
                                                                            ? formatterThousand(descontoValue)
                                                                            : formatterThousand((descontoValue / 100) * totalRows)}
                                                                        </a>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item sx={{ml: 3.5}}>
                                                                <b>+</b>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container rowSpacing={1}>
                                                                    <Grid item xs={12}
                                                                            sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>Frete (R$)</a>
                                                                    </Grid>
                                                                    <Grid item xs={12}
                                                                            sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>{formatterThousand(freteValue)}</a>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item sx={{ml: 3.5}}>
                                                                <b>=</b>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container rowSpacing={1}>
                                                                    <Grid item xs={12}
                                                                            sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>Total (R$)</a>
                                                                    </Grid>
                                                                    <Grid item xs={12}
                                                                            sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>{formatterThousand(totalDiscountedValue)}</a>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </React.Fragment>
                                        )}
                                        <Grid item xs={12} sx={{marginTop: 2}}>
                                            <span>
                                                <p className="title-container-p">Informações de pagamento</p>
                                            </span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} /> 
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <FilterSingleOption
                                                        filterOptions={formaPagamentoOptions}
                                                        selectedOption={selectedFormaPagamento}
                                                        setSelectedOption={setSelectedFormaPagamento}
                                                        placeholder="Forma de pagamento"
                                                        disabled={repetirCompra}
                                                        inputError={inputError}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <InputCurrency
                                                        valor={totalDiscountedValue}
                                                        setValor={setTotalDiscountedValue}
                                                        title={"Valor a pagar (R$)"}
                                                        disabled={cadastrarProduto}
                                                        error={inputError}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FilterSingleOption
                                                        filterOptions={parcelaOptions}
                                                        selectedOption={repetirCompra ? "1" : selectedParcela}
                                                        setSelectedOption={setSelectedParcela}
                                                        placeholder="Condição de pagamento"
                                                        disabled={repetirCompra}
                                                    />
                                                </Grid>
                                                {
                                                    ["2", "3", "4", "5", "12"].includes(selectedParcela) && (
                                                        <Grid item>
                                                            <InputNumber
                                                                value={intervaloParcelas}
                                                                setValue={setIntervaloParcelas}
                                                                width={"250px"}
                                                                label="Intervalo entre parcelas (dias)"
                                                            />
                                                        </Grid>
                                                    )
                                                }
                                                <Grid item>
                                                    <FilterDayMonthYear
                                                        style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                                        handleSubmit={handleDayMonthYear}
                                                        size={"small"}
                                                        width={"250px"}
                                                        iconPosition={"end"}
                                                        label={"Vencimento"}
                                                        setState={setParcelaDataVencimento}
                                                        value={parcelaDataVencimento}
                                                        disabled={repetirCompra}
                                                        inputError={inputError}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FilterSingleOption
                                                        filterOptions={contasOptions}
                                                        selectedOption={selectedConta}
                                                        setSelectedOption={setSelectedConta}
                                                        placeholder={pago ? "Conta de pagamento *" : "Conta de pagamento"}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <GrayButton
                                                        title={"Editar parcelas"}
                                                        onClick={() => setOpenParcelasModal(true)}
                                                        disabled={selectedParcela <= 1 || repetirCompra || totalDiscountedValue <= 0}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <a style={{
                                                        ...styleText,
                                                        color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                                    }}>Pago</a>
                                                    <Checkbox
                                                        checked={pago}
                                                        onChange={(event) => setPago(event.target.checked)}
                                                    />
                                                    {
                                                        !pago && (
                                                            <React.Fragment>
                                                                <a 
                                                                    style={{
                                                                        ...styleText,
                                                                        color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                                                    }}
                                                                >
                                                                        Agendado
                                                                </a>
                                                                    <Checkbox
                                                                    checked={agendado}
                                                                    onChange={(event) => setAgendado(event.target.checked)}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                                        </Grid>
                                        <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 2, flexWrap: "wrap"}}>
                                            <FilterDayMonthYear
                                                handleSubmit={handleDayMonthYear}
                                                value={selectedDataPagamento}
                                                setState={setSelectedDataPagamento}
                                                size={"small"}
                                                width={"250px"}
                                                iconPosition={"end"}
                                                label={"Data do pagamento"}
                                                isEditConta
                                                inputError={inputError}
                                            />
                                            <InputCurrency valor={valorIsPago} setValor={setValorIsPago} title={"Valor"}/>
                                            <InputCurrency valor={juros} setValor={setJuros} title={"Juros"}/>
                                            <InputCurrency valor={multa} setValor={setMulta} title={"Multa"}/>
                                            <InputCurrency valor={desconto} setValor={setDesconto} title={"Desconto"}/>
                                        </Grid>
                                        <Grid item xs={12} sx={{marginLeft: 2, padding: 2, display: "flex", alignItems: "start", justifyContent: "space-between", border: `1px solid ${temaEscuro ? "#616169" : "#C3C3C3"}`, borderRadius: 1, marginTop: 2}}>
                                            <span style={{...styleText, fontWeight: "700", fontSize: 20, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>
                                                Totais
                                            </span>
                                            <Grid container sx={{width: "auto", display: "flex", gap: 6}}>
                                                <Grid item sx={{display: "flex", gap: 1, flexDirection: "column", alignItems: "end"}}>
                                                    <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Valor em Aberto (R$)</span>
                                                    <span style={{color: RED_INFO, fontWeight: 600}}>{format(totalDiscountedValue - valorIsPago)}</span>
                                                </Grid>
                                                <Grid item sx={{display: "flex", gap: 1, flexDirection: "column", alignItems: "end"}}>
                                                    <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Total a pagar (R$)</span>
                                                    <span style={{fontWeight: 600}}>{format((totalDiscountedValue + juros + multa) - desconto, true)}</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{marginTop: 2}}>
                                            <span>
                                                <p className="title-container-p">Descrição</p>
                                            </span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} /> 
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                label={"Descrição"}
                                                id="input-observacoes-textfield"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                maxRows={6}
                                                value={descricao}
                                                onChange={(event) => {
                                                    setDescricao(event.target.value);
                                                }}
                                                sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sx={{backgroundColor: "transparent", marginBottom: "15px", marginTop: 3}}>
                                            <Tabs
                                                value={selectedTab}
                                                onChange={(e, value) => setSelectedTab(value)}
                                                TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                                                className="buttonTabs"
                                            >
                                                <Tab
                                                    value={"observacoes"}
                                                    label="Observações"
                                                    sx={{
                                                        borderRadius: "20px",
                                                        color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                        backgroundColor: selectedTab === "observacoes" ? YELLOW_BG_UX : "transparent",
                                                        "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                            color: BLACK_LABEL_UX,
                                                        },
                                                    }}
                                                />
                                                <Tab
                                                    value={"anexo"}
                                                    label="Anexo"
                                                    sx={{
                                                        borderRadius: "20px",
                                                        color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                        backgroundColor: selectedTab === "anexo" ? YELLOW_BG_UX : "transparent",
                                                        "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                            color: BLACK_LABEL_UX,
                                                        },
                                                    }}
                                                />
                                            </Tabs>
                                        </Grid>
                                        {selectedTab === "observacoes" ? (
                                            <Grid item xs={12}>
                                                <TextField
                                                    label={"Observações"}
                                                    id="input-observacoes-textfield"
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    maxRows={6}
                                                    value={observacoes}
                                                    onChange={(event) => {
                                                        setObservacoes(event.target.value);
                                                    }}
                                                    sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                                                />
                                            </Grid>
                                        ) : (
                                            <AnexosReceita
                                                anexos={anexos}
                                                setAnexos={setAnexos}
                                            />
                                        )}
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}
                                                    sx={{display: "flex", justifyContent: "end"}}>
                                                <Grid item>
                                                    <GrayButton
                                                        title={"Voltar"}
                                                        onClick={handleNavExtrato}
                                                    />
                                                </Grid>
                                                <Grid item sx={{display: "flex"}}>
                                                    <YellowButton
                                                        title={"Salvar"}
                                                        onClick={() => postSaveCompra()}
                                                        borderRadius={{borderRadius: "5px 0 0 5px"}}
                                                    />
                                                    <SaveOptions
                                                        action={postSaveCompra}
                                                        cleanStates={cleanCompraInputs}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </React.Fragment>
                        )
                    }
                </React.Fragment>
            </div>
        </React.Fragment>
    );
};

export default GerarCompra;