import React  from "react";
import {smallPaperStyle, GRAY_LABEL_UX} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {BLUE_THEME, WHITE_ESCRITA_THEME, WHITE_THEME_BLACK, BORDER_TABLE, BORDER_PAPER} from "../../shared/utils";
import Divider from "@mui/material/Divider";
import YellowButton from "../Buttons/YellowButton";
import GrayButton from "../Buttons/GrayButton";
import { useNavigate } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";


const CertificadoExpiradoModal = (props) => {
    const {openModal, setOpenModal, infoTitle, certificadosExpirados} = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const nav = useNavigate();

    return(
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="info-modal"
            >
                <Paper sx={{...smallPaperStyle, maxWidth: "50%", backgroundColor: temaEscuro && BLUE_THEME}}>
                    <Grid item xs={10} className="start" sx={{display: "flex", alignItems: "center", mb: 1}}>
                        <WarningIcon
                            sx={{
                                color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX,
                                fontSize: "30px",
                                marginRight: "1rem"
                            }}
                        />
                        <span style={{fontWeight: "bold", fontSize: "18px", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>{infoTitle}</span>
                    </Grid>
                    <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} />
                    <Grid sx={{marginTop: 3}}>
                        <span>
                            Encontramos certificados que ficarão obsoletos em cerca de 1 mês, por favor atualize-os para não perdermos o acesso às informações referentes aos bancos.
                        </span>
                    </Grid>
                    <Grid>
                        <Grid container sx={{marginBottom: 1, mt: 1.5}}>
                            {
                                certificadosExpirados.map((certificado, index) => {
                                    return (
                                        <Grid item xs={12} key={index} sx={{mb: 1, display: "flex", flexDirection: "column"}}>
                                                <Grid item>
                                                    <span style={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontWeight: "bold"}}>{certificado.banco}:</span>
                                                </Grid>
                                                <Grid item>
                                                    <span style={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>{certificado.nome}</span>
                                                </Grid>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                    <Grid item sx={{display: "flex", justifyContent: "end", gap: 1.5}}>
                        <GrayButton
                            title="Agora não"
                            onClick={() => setOpenModal(false)}
                        />
                        <YellowButton
                            onClick={() => {
                                setOpenModal(false);
                                nav("/upload_certificado");
                            }}
                            title="Atualizar certificados"
                        />
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );                  
};

export default CertificadoExpiradoModal;