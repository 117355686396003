import React, { useState, useEffect } from "react";
import Header from "../../components/Titles/Header";
import {changeStore} from "../../actions/StoreActions";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import {
    BLACK_TABLE,
    BLUE_THEME,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    BORDER_TABLE,
    formatterPercentage,
    formatterThousand,
    getCurrentDate,
    GRAY_BG_UX,
    GRAY_LABEL_UX,
    GRAY_LABEL_UX_THEME,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle,
    styleText,
    WHITE_ESCRITA_THEME,
    WHITE_THEME_BLACK,
    YELLOW_BG_UX,
    BLACK_LABEL_UX
} from "../../shared/utils";
import FilterSingleOption from "../../components/Filter/FilterSingleOption";
import api from "../../axios";
import FilterDayMonthYear from "../../components/Filter/FilterDayMonthYear";
import moment from "moment";
import InputSearch from "../../components/Input/InputSearch";
import AnexosReceita from "../../components/Tabs/AnexosReceita";
import RateiosReceita from "../../components/Tabs/RateiosReceita";
import CreateOptionModal from "../../components/Modal/CreateOptionModal";
import RateioCentroCustoModal from "../../components/Modal/RateioCentroCustoModal";
import {cobrarSempreOptions} from "./Enums";
import YellowButton from "../../components/Buttons/YellowButton";
import ParcelasModal from "../../components/Modal/ParcelasModal";
import GrayButton from "../../components/Buttons/GrayButton";
import {showSnackMessage} from "../../actions/SnackActions";
import { useNavigate, useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";
import InputCurrency from "../../components/Input/InputCurrency";
import SaveOptions from "../../components/Buttons/SaveOptions";
import { useHotkeys } from "react-hotkeys-hook";


const Movimentacao = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {state} = useLocation();

    const [componente, setComponente] = useState("");
    const [locationId, setLocationId] = useState(0);
	const currentMonthDate = moment(getCurrentDate()).toDate();
    const [selectedTab, setSelectedTab] = useState("observacoes");
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [optionCreateModal, setOptionCreateModal] = useState("");
    const [openRateioCentroCustoModal, setOpenRateioCentroCustoModal] = useState(false);
    const [categoriaRateioCentroCusto, setCategoriaRateioCentroCusto] = useState("");
    const [valorCategoriaRateioCentroCusto, setValorCategoriaRateioCentroCusto] = useState(0);
    const [indexRateioCentroCusto, setIndexRateioCentroCusto] = useState(0);

    const [clienteOptions, setClientesOptions] = useState([]);
    const [categoriaOptions, setCategoriaOptions] = useState([]);
    const [centroCustoOptions, setCentroCustoOptions] = useState([]);
    const [contasOptions, setContasOptions] = useState([]);

    const [selectedCliente, setSelectedCliente] = useState(null);
    const [selectedDataCompra, setSelectedDataCompra] = useState(state?.data || currentMonthDate);
    const [descricaoCompra, setDescricaoCompra] = useState(state?.descricao || "");
    const [valorCompra, setValorCompra] = useState(state?.valor || 0);

    const [hasRateio, setHasRateio] = useState(false);
    const [rateios, setRateios] = useState([{
        categoria: null,
        valor_total: 0,
        porcentagem: 0,
        centro_custo: null,
    }]);

    const [selectedCategoria, setSelectedCategoria] = useState(null);
    const [selectedCentroCusto, setSelectedCentroCusto] = useState(null);
    const [codigoReferencia, setCodigoReferencia] = useState("");

    const [repetirCompra, setRepetirCompra] = useState(false);
    const [configRepeticao, setConfigRepeticao] = useState(null);
    const [selectedCobrarSempre, setSelectedCobrarSempre] = useState(null);
    const [selectedPrimeiroVencimento, setSelectedPrimeiroVencimento] = useState(currentMonthDate);

    const [selectedParcelamento, setSelectedParcelamento] = useState(null);
    const [selectedFormaPagamento, setSelectedFormaPagamento] = useState(null);
    const [openParcelasModal, setOpenParcelasModal] = useState(false);
    const [parcelas, setParcelas] = useState([]);

    const [selectedDataVencimento, setSelectedDataVencimento] = useState(state?.data || currentMonthDate);
    const [selectedConta, setSelectedConta] = useState(null);

    const [hasRecebido, setHasRecebido] = useState(false);

    const [observacoes, setObservacoes] = useState("");
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);

    const [prevSelectedCliente, setPrevSelectedCliente] = useState(null);
    const [prevValorCompra, setPrevValorCompra] = useState(0);
    const [prevHasRateio, setPrevHasRateio] = useState(false);
    const [prevSelectedCategoria, setPrevSelectedCategoria] = useState(null);
    const [prevSelectedCentroCusto, setPrevSelectedCentroCusto] = useState(null);
    const [prevRepetirCompra, setPrevRepetirCompra] = useState(false);
    const [prevAnexos, setPrevAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);
    const [prevParcelamento, setPrevParcelamento] = useState(null);
    const [prevFormaPagamento, setPrevFormaPagamento] = useState(null);
    const [prevConta, setPrevConta] = useState(null);
    const [prevRecebido, setPrevRecebido] = useState(false);
    const [prevConfigRepeticao, setPrevConfigRepeticao] = useState(null);
    const [prevCobrarSempre, setPrevCobrarSempre] = useState(null);

    const [changesList, setChangesList] = useState([]);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    useHotkeys("ctrl+z", () => {
        const change = changesList.pop();

        switch (change) {
            case "clienteFornecedor":
                setSelectedCliente(prevSelectedCliente);
                break;
            case "valor":
                setValorCompra(prevValorCompra);
                break;
            case "categoria":
                setSelectedCategoria(prevSelectedCategoria);
                break;
            case "centroCusto":
                setSelectedCentroCusto(prevSelectedCentroCusto);
                break;
            case "hasRateio":
                setHasRateio(prevHasRateio);
                break;
            case "repetirCompra":
                setRepetirCompra(prevRepetirCompra);
                break;
            case "parcelamento":
                setSelectedParcelamento(prevParcelamento);
                break;
            case "anexos":
                setAnexos(prevAnexos);
                break;
            case "formaPagamento":
                setSelectedFormaPagamento(prevFormaPagamento);
                break;
            case "conta":
                setSelectedConta(prevConta);
                break;
            case "recebido":
                setHasRecebido(prevRecebido);
                break;
            case "configRepeticao":
                setConfigRepeticao(prevConfigRepeticao);
                break;
            case "cobrarSempre":
                setSelectedCobrarSempre(prevCobrarSempre);
                break;
            default:
                break;
        }
    });

    const formaPagamentoOptions = [
        "Boleto via outros bancos",
        "Cashback",
        "Cheque",
        "Cartão de crédito via outros bancos",
        "Cartão de débito via outros bancos",
        "Carteira digital",
        "Crédito da loja",
        "Crédito virtual",
        "Débito Automático",
        "Depósito bancário",
        "Dinheiro",
        "Outros",
        "Pix",
        "Programa de fideliade",
        "Sem pagamento",
        "Transferência bancária",
        "Vale-alimentação",
        "Vale-combustível",
        "Vale-presente",
        "Vale-refeição",
    ];

    useEffect(() => {
        const url = location.href || window.location.href;
        if (url.includes("despesa")) {
            setComponente("Despesa");
        } else {
            setComponente("Receita");
        }
    }, [location]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);

        setLocationId(parseInt(query.get("estabelecimento_id")));
        if (locationId !== 0 && locationId != null && !isNaN(locationId)) {
            dispatch(changeStore(locationId));
        }
    }, [locationId]);

    useEffect(() => {
        getInputsOptions();
    }, [estabelecimentoId]);

    useEffect(() => {
        if (selectedParcelamento) {
            handleParcelas();
        }
    }, [selectedParcelamento, valorCompra]);

    const postCreateReceita = (redirect = true) => {
        let formData = new FormData();

        formData.append("estabelecimento_id", estabelecimentoId);
        if (selectedCliente != null) {
            formData.append("cliente_id", selectedCliente.value);
        }
        formData.append("data_compra", moment(selectedDataCompra).format("DD-MM-YYYY"));
        formData.append("descricao", descricaoCompra);
        formData.append("valor_total", valorCompra);
        formData.append("has_rateio", hasRateio);
        if (hasRateio) {
            formData.append("rateios", JSON.stringify(rateios));
        } else {
            formData.append("centro_custo_id", selectedCentroCusto?.value || 0);
        }
        if (selectedCategoria && !rateios.length > 1) {
            formData.append("categoria_id", selectedCategoria.value);
        }
        formData.append("codigo_referencia", codigoReferencia);
        formData.append("repetir_compra", repetirCompra);
        if (repetirCompra) {
            formData.append("repetir_compra", repetirCompra);
            formData.append("config_repeticao", configRepeticao.value);
            formData.append("cobrar_sempre", selectedCobrarSempre.value);
            formData.append("primeiro_vencimento", moment(selectedPrimeiroVencimento).format("DD-MM-YYYY"));
        } else {
            formData.append("parcelamento", selectedParcelamento);
            formData.append("parcelas", JSON.stringify(parcelas));
            formData.append("data_vencimento", moment(selectedDataVencimento).format("DD-MM-YYYY"));
        }
        formData.append("forma_pagamento", selectedFormaPagamento);
        if (selectedConta != null) {
            formData.append("conta_recebimento", selectedConta.value);
        }
        formData.append("recebido", hasRecebido);
        formData.append("observacoes", observacoes);
        if (anexos[0].arquivo != null){
            formData.append("quantidade_anexo", anexos.length);
            anexos.forEach((anexo, index) => {
                formData.append(`anexos[${index}][forma_anexo]`, anexo.forma_anexo);
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(`anexos[${index}][tipo_anexo]`, anexo.tipo_anexo);
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }
        formData.append("tipo_movimentacao", componente);
        api.PostGenerateMovimentacao(formData).then(() => {
            dispatch(showSnackMessage({message: `${componente} gerado(a) com sucesso!`, severity: "success"}));
            if (redirect) {
                navigate("/extrato_movimentacoes");
            }
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const getInputsOptions = () => {
        if (estabelecimentoId !== 0) {
            let dataRequest = {
                estabelecimento_id: estabelecimentoId,
                papel_pessoa: componente === "Receita" ? "Cliente" : "Fornecedor",
            };
            api.GetFornecedores(dataRequest).then(response => {
                setClientesOptions(response.data);
            });
            api.GetCategorias({...dataRequest, "tipo": "Receita"}).then(response => {
                setCategoriaOptions(response.data);
            });
            api.GetCentroCustos(dataRequest).then(response => {
                setCentroCustoOptions(response.data);
            });
            api.RetrieveContas(dataRequest).then(response => {
                setContasOptions(response.data);
            });
        }
    };

    const handleDayMonthYear = (ano, mes, dia, setState) => {
        setState(`${dia}-${mes}-${ano}`);
    };

    const handleParcelas = (skipIndex = false, skipRow = {}, alteredField = "") => {
        let _parcelas = [];
        let valorTotal = 0;
        let percentTotal = 0;
        let _totalValue = valorCompra;
        let parcelasQuantidade = selectedParcelamento === "À vista" ? 1 : selectedParcelamento;
        let parcelamento = selectedParcelamento === "À vista" ? 1 : selectedParcelamento;
        for (let i = 0; i < parcelamento; i++) {
            if ((skipIndex || typeof skipIndex === "number") && i === skipIndex) {
                if (alteredField === "valor") {
                    if (skipRow.valor >= valorCompra || skipRow.valor === 0) {
                        skipRow.error = true;
                        return;
                    }
                    _totalValue = valorCompra - skipRow.valor;
                    skipRow.percentual = parseFloat(formatterPercentage((skipRow.valor * 100) / valorCompra, 2));
                    percentTotal += skipRow.percentual;
                    skipRow.error = false;
                } else {
                    if (skipRow.percentual >= 100 || skipRow.percentual === 0) {
                        skipRow.error = true;
                        return;
                    }
                    percentTotal += parseFloat(skipRow.percentual);
                    skipRow.valor = parseFloat(formatterThousand(valorCompra * (skipRow.percentual / 100), true));
                    _totalValue -= skipRow.valor;
                    skipRow.error = false;
                }
                parcelasQuantidade = parcelamento - 1;
                _parcelas.push(skipRow);
                continue;
            }
            let valorParcela = 0;
            let percentParcela = 0;
            if (i === parcelamento - 1) {
                valorParcela = formatterThousand(_totalValue - valorTotal, true);
                percentParcela = formatterPercentage(100 - percentTotal, 2);
            } else {
                valorParcela = formatterThousand(_totalValue / parseFloat(parcelasQuantidade), true);
                percentParcela = formatterPercentage((valorParcela * 100) / valorCompra, 2);
            }
            let parcelaData = moment(selectedDataVencimento || moment(getCurrentDate()).toDate()).add(i, "months");
            _parcelas.push({
                "valor": parseFloat(valorParcela),
                "percentual": parseFloat(percentParcela),
                "data_vencimento": parcelaData.toDate(),
                "descricao": ""
            });
            valorTotal += parseFloat(valorParcela);
            percentTotal += parseFloat(percentParcela);
        }
        setParcelas(_parcelas);
    };

    const handleNavExtrato = () => {
        navigate("/extrato_movimentacoes");
    };

    const cleanReceitaInputs = () => {
        setSelectedCliente(null);
        setSelectedDataCompra(currentMonthDate);
        setDescricaoCompra("");
        setValorCompra(0);
        setHasRateio(false);
        setRateios([{
            categoria: null,
            valor_total: 0,
            porcentagem: 0,
            centro_custo: null,
        }]);
        setSelectedCategoria(null);
        setSelectedCentroCusto(null);
        setCodigoReferencia("");
        setRepetirCompra(false);
        setConfigRepeticao(null);
        setSelectedCobrarSempre(null);
        setSelectedPrimeiroVencimento(currentMonthDate);
        setSelectedParcelamento(null);
        setSelectedFormaPagamento(null);
        setOpenParcelasModal(false);
        setParcelas([]);
        setSelectedDataVencimento(currentMonthDate);
        setSelectedConta(null);
        setHasRecebido(false);
        setObservacoes("");
        setAnexos([
            {
                forma_anexo: "Arquivo",
                arquivo: null,
                tipo_anexo: "Contrato",
                descricao: "",
            },
        ]);
    };

    return (
        <React.Fragment>
            <div className="main" data-testid="despesas">
                <CreateOptionModal
                    openModal={openCreateModal}
                    setOpenModal={setOpenCreateModal}
                    option={optionCreateModal}
                    getInputsOptions={getInputsOptions}
                />
                <RateioCentroCustoModal
                    openModal={openRateioCentroCustoModal}
                    setOpenModal={setOpenRateioCentroCustoModal}
                    categoria={categoriaRateioCentroCusto}
                    valorCategoria={valorCategoriaRateioCentroCusto}
                    indexRateioCentroCusto={indexRateioCentroCusto}
                    rateios={rateios}
                    setRateios={setRateios}
                    centroCustoOptions={centroCustoOptions}
                />
                <ParcelasModal
                    openModal={openParcelasModal}
                    setOpenModal={setOpenParcelasModal}
                    formaPagamento={selectedFormaPagamento}
                    valorPagamento={valorCompra}
                    condicaoPagamento={selectedParcelamento}
                    parcelas={parcelas}
                    setParcelas={setParcelas}
                    handleParcelas={handleParcelas}
                />
                <Header title={componente === "Receita" ? "Nova receita" : "Nova despesa"} subTitle="Extrato de movimentações" previousPage="/extrato_movimentacoes" />
                <Paper sx={{
                    ...paperStyle,
                    backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <span>
                                <p className="title-container-p">
                                    Informações da compra
                                </p>
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item sx={{mr: "2rem"}}>
                                    <FilterSingleOption
                                        filterOptions={clienteOptions}
                                        selectedOption={selectedCliente}
                                        setSelectedOption={(e) => {
                                            setChangesList([...changesList, "clienteFornecedor"]);
                                            setPrevSelectedCliente(selectedCliente);
                                            setSelectedCliente(e);
                                        }}
                                        placeholder={componente === "Receita" ? "Cliente" : "Fornecedor"}
                                        width={"350px"}
                                        canCreate
                                        setOpenCreateModal={setOpenCreateModal}
                                        setOptionCreateModal={setOptionCreateModal}
                                    />
                                </Grid>
                                <Grid item>
                                    <FilterDayMonthYear
                                        disableMinDate
                                        style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                        handleSubmit={handleDayMonthYear}
                                        value={selectedDataCompra}
                                        setState={setSelectedDataCompra}
                                        size={"small"}
                                        width={"250px"}
                                        iconPosition={"end"}
                                        label={"Data da compra"}
                                    />
                                </Grid>
                                <Grid item>
                                    <InputSearch
                                        functionProps={() => {}}
                                        setSearchValue={setDescricaoCompra}
                                        searchValue={descricaoCompra}
                                        label={"Descrição *"}
                                        width={"575px"}
                                    />
                                </Grid>
                                <Grid item>
                                    <InputCurrency
                                        title={"Valor (R$)"}
                                        valor={valorCompra}
                                        setValor={setValorCompra}
                                        changesList={changesList}
                                        setChangesList={setChangesList}
                                        setPrevValor={setPrevValorCompra}
                                    />
                                </Grid>
                                <Grid item>
                                    <Checkbox
                                        checked={hasRateio}
                                        icon={<CircleOutlinedIcon/>}
                                        checkedIcon={<CircleIcon/>}
                                        onChange={(event) => {
                                            setChangesList([...changesList, "hasRateio"]);
                                            setPrevHasRateio(hasRateio);
                                            setHasRateio(event.target.checked);
                                        }}
                                    />
                                    <a style={{
                                        ...styleText,
                                        color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                    }}>Habilitar rateio</a>
                                </Grid>
                                <Grid item sx={{mr: "2rem"}}>
                                    <FilterSingleOption
                                        sx={{borderRadius: 0}}
                                        filterOptions={categoriaOptions}
                                        selectedOption={selectedCategoria}
                                        setSelectedOption={(e) => {
                                            setChangesList([...changesList, "categoria"]);
                                            setPrevSelectedCategoria(selectedCategoria);
                                            setSelectedCategoria(e);
                                        }}
                                        placeholder={"Categoria *"}
                                        width={"350px"}
                                        disabled={hasRateio}
                                        canCreate
                                        setOpenCreateModal={setOpenCreateModal}
                                        setOptionCreateModal={setOptionCreateModal}
                                    />
                                </Grid>
                                <Grid item sx={{mr: "2rem"}}>
                                    <FilterSingleOption
                                        filterOptions={centroCustoOptions}
                                        selectedOption={selectedCentroCusto}
                                        setSelectedOption={(e) => {
                                            setChangesList([...changesList, "centroCusto"]);
                                            setPrevSelectedCentroCusto(selectedCentroCusto);
                                            setSelectedCentroCusto(e);
                                        }}
                                        placeholder={"Centro de custo"}
                                        width={"350px"}
                                        canCreate
                                        setOpenCreateModal={setOpenCreateModal}
                                        setOptionCreateModal={setOptionCreateModal}
                                    />
                                </Grid>
                                <Grid item>
                                    <InputSearch
                                        functionProps={() => {}}
                                        setSearchValue={setCodigoReferencia}
                                        searchValue={codigoReferencia}
                                        className="input-text"
                                        label={"Código de referência"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {hasRateio && (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <a>Informar categoria e centro de custo</a>
                                </Grid>
                                <Grid item xs={12} sx={{
                                    border: 0,
                                    borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                    mb: 2.5,
                                    ml: 2,
                                }}/>
                                <RateiosReceita
                                    rateios={rateios}
                                    setRateios={setRateios}
                                    categoriaOptions={categoriaOptions}
                                    centroCustoOptions={centroCustoOptions}
                                    valorCompra={valorCompra}
                                    setOpenCreateModal={setOpenCreateModal}
                                    setOptionCreateModal={setOptionCreateModal}
                                    setOpenRateioCentroCustoModal={setOpenRateioCentroCustoModal}
                                    setCategoriaRateioCentroCusto={setCategoriaRateioCentroCusto}
                                    setValorCategoriaRateioCentroCusto={setValorCategoriaRateioCentroCusto}
                                    setIndexRateioCentroCusto={setIndexRateioCentroCusto}
                                />
                            </React.Fragment>

                        )}
                        <Grid item xs={repetirCompra ? 2 : 12}>
                            <a style={{
                                ...styleText,
                                color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                            }}>Repetir lançamento?</a>
                            <Checkbox
                                checked={repetirCompra}
                                icon={<CircleOutlinedIcon/>}
                                checkedIcon={<CircleIcon/>}
                                onChange={(event) => {
                                    setChangesList([...changesList, "repetirCompra"]);
                                    setPrevRepetirCompra(repetirCompra);
                                    setRepetirCompra(event.target.checked);
                                }}
                            />
                        </Grid>
                        {
                            repetirCompra && (
                                <Grid item xs={10}>
                                    <FilterSingleOption
                                        filterOptions={["Diariamente", "Semanalmente", "Mensalmente", "Anualmente"]}
                                        selectedOption={configRepeticao}
                                        setSelectedOption={(e) => {
                                            setChangesList([...changesList, "configRepeticao"]);
                                            setPrevConfigRepeticao(configRepeticao);
                                            setConfigRepeticao(e);
                                        }}
                                        placeholder={"Configuração de repetição"}
                                        width={"300px"}
                                    />
                                </Grid>
                            )
                        }
                        <Grid item xs={12} sx={{marginTop: 2}}>
                            <span>
                                <p className="title-container-p">Condição de pagamento</p>
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                {
                                    repetirCompra ? (
                                        <React.Fragment>
                                            <Grid item>
                                                <FilterSingleOption
                                                    filterOptions={cobrarSempreOptions}
                                                    selectedOption={selectedCobrarSempre}
                                                    setSelectedOption={(e) => {
                                                        setChangesList([...changesList, "cobrarSempre"]);
                                                        setPrevCobrarSempre(selectedCobrarSempre);
                                                        setSelectedCobrarSempre(e);
                                                    }}
                                                    placeholder={"Cobrar sempre no"}
                                                    width={"200px"}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FilterDayMonthYear
                                                    disableMinDate
                                                    style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                                    handleSubmit={handleDayMonthYear}
                                                    value={selectedPrimeiroVencimento}
                                                    setState={setSelectedPrimeiroVencimento}
                                                    size={"small"}
                                                    width={"250px"}
                                                    iconPosition={"end"}
                                                    label={"Primeiro vencimento"}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Grid item>
                                                <FilterSingleOption
                                                    filterOptions={["À vista", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                                                    selectedOption={selectedParcelamento}
                                                    setSelectedOption={(e) =>{
                                                        setChangesList([...changesList, "parcelamento"]);
                                                        setPrevParcelamento(selectedParcelamento);
                                                        setSelectedParcelamento(e);
                                                    }}
                                                    placeholder={"Parcelamento *"}
                                                    width={"200px"}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FilterDayMonthYear
                                                    disableMinDate
                                                    style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                                    handleSubmit={handleDayMonthYear}
                                                    value={selectedDataVencimento}
                                                    setState={setSelectedDataVencimento}
                                                    size={"small"}
                                                    width={"250px"}
                                                    iconPosition={"end"}
                                                    label={"Vencimento"}
                                                />
                                            </Grid>

                                        </React.Fragment>
                                    )
                                }
                                <Grid item>
                                    <FilterSingleOption
                                        filterOptions={formaPagamentoOptions}
                                        selectedOption={selectedFormaPagamento}
                                        setSelectedOption={(e) => {
                                            setChangesList([...changesList, "formaPagamento"]);
                                            setPrevFormaPagamento(selectedFormaPagamento);
                                            setSelectedFormaPagamento(e);
                                        }}
                                        placeholder={"Forma de pagamento"}
                                    />
                                </Grid>
                                <Grid item>
                                    <FilterSingleOption
                                        filterOptions={contasOptions}
                                        selectedOption={selectedConta}
                                        setSelectedOption={(e) => {
                                            setChangesList([...changesList, "conta"]);
                                            setPrevConta(selectedConta);
                                            setSelectedConta(e);
                                        }}
                                        placeholder={
                                        hasRecebido ?
                                            (componente === "Receita" ? "Conta de recebimento *" : "Conta de pagamento *")
                                            :
                                            (componente === "Receita" ? "Conta de recebimento" : "Conta de pagamento")
                                        }
                                    />
                                </Grid>
                                {
                                    !repetirCompra && (
                                        <Grid item>
                                            <Checkbox
                                                checked={hasRecebido}
                                                onChange={(event) => {
                                                    setChangesList([...changesList, "recebido"]);
                                                    setPrevRecebido(hasRecebido);
                                                    setHasRecebido(event.target.checked);
                                                }}
                                            />
                                            <a style={{
                                                ...styleText,
                                                color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX,
                                                fontSize: "12px",
                                            }}>{componente === "Receita" ? "Recebido" : "Pago"}</a>
                                        </Grid>
                                    )
                                }
                                {
                                    selectedParcelamento !== "À vista" && (
                                        <Grid item xs={12}>
                                            <GrayButton
                                                title={"Editar parcelas"}
                                                onClick={() => setOpenParcelasModal(true)}
                                            />
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sx={{backgroundColor: "transparent", marginBottom: "15px", marginTop: 3}}>
                            <Tabs
                                value={selectedTab}
                                onChange={(e, value) => setSelectedTab(value)}
                                TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                                className="buttonTabs"
                            >
                                <Tab
                                    value={"observacoes"}
                                    label="Observações"
                                    sx={{
                                        borderRadius: "20px",
                                        color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                        backgroundColor: selectedTab === "observacoes" ? YELLOW_BG_UX : "transparent",
                                        "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                            color: BLACK_LABEL_UX,
                                        },
                                    }}
                                />
                                <Tab
                                    value={"anexo"}
                                    label="Anexo"
                                    sx={{
                                        borderRadius: "20px",
                                        color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                        backgroundColor: selectedTab === "anexo" ? YELLOW_BG_UX : "transparent",
                                        "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                            color: BLACK_LABEL_UX,
                                        },
                                    }}
                                />
                            </Tabs>
                        </Grid>
                        {selectedTab === "observacoes" ? (
                            <Grid item xs={12}>
                                <TextField
                                    label={"Observações"}
                                    id="input-observacoes-textfield"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    maxRows={6}
                                    value={observacoes}
                                    onChange={(event) => {
                                        setObservacoes(event.target.value);
                                    }}
                                    sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                                />
                            </Grid>
                        ) : (
                            <AnexosReceita
                                anexos={anexos}
                                setAnexos={setAnexos}
                                setPrevAnexos={setPrevAnexos}
                                setChangesList={setChangesList}
                                changesList={changesList}
                            />
                        )}
                        <Grid item xs={12} sx={{display: "flex", justifyContent: "end", gap: 2}}>
                            <GrayButton
                                title={"Voltar"}
                                onClick={handleNavExtrato}
                            />
                            <Grid sx={{display: "flex"}}>
                                <YellowButton
                                    title={"Salvar"}
                                    onClick={() => postCreateReceita()}
                                    borderRadius={{borderRadius: "5px 0 0 5px"}}
                                />
                                <SaveOptions
                                    action={postCreateReceita}
                                    cleanStates={cleanReceitaInputs}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </React.Fragment>
    );
};

export default Movimentacao;