import React  from "react";
import {smallPaperStyle} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {BLUE_THEME} from "../../shared/utils";
import ModalTitle from "../Titles/ModalTitle";


const ShortCutsModal = ({openShortCuts, setOpenShortCuts}) => {
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    return (
        <Modal
            open={openShortCuts}
            onClose={() => setOpenShortCuts(false)}
        >
            <Paper sx={{...smallPaperStyle, maxWidth: "auto", backgroundColor: temaEscuro && BLUE_THEME, pt: 3}}>
                <ModalTitle
                    title={"Atalhos do VXMF"}
                    setOpenModal={setOpenShortCuts}
                />
                <Grid sx={{marginTop: 2, display: "flex", flexDirection: "column", gap: 1}}>
                    <span><strong>ALT+C:</strong> Nova compra</span>
                    <span><strong>ALT+D:</strong> Nova despesa</span>
                    <span><strong>ALT+R:</strong> Nova receita</span>
                    <span><strong>ALT+L:</strong> Novo cliente</span>
                    <span><strong>ALT+F:</strong> Novo fornecedor</span>
                    <span><strong>ALT+W:</strong> Nova transportadora</span>
                    <span><strong>CTRL+V:</strong> Insere a captura de tela nos anexos</span>
                    <span><strong>CTRL+Z:</strong> Desfaz o preenchimento das despesas e receitas</span>
                </Grid>
            </Paper>
        </Modal>
    );
};

export default ShortCutsModal;