import React, { useEffect, useState } from "react";
import Header from "../../components/Titles/Header";
import { Grid, Paper, Skeleton } from "@mui/material";
import { PAPER_PADDING_THEME, paperStyle } from "../../shared/utils";
import DataGridWithPagination from "../../components/DataGrid/DataGridWithPagination";
import api from "../../axios";
import { useDispatch, useSelector } from "react-redux";
import { showSnackMessage } from "../../actions/SnackActions";
import YellowButton from "../../components/Buttons/YellowButton";
import CreateOptionModal from "../../components/Modal/CreateOptionModal";
import { useLocation } from "react-router-dom";
import UpdateOptionModal from "../../components/Modal/UpdateOptionModal";
import FilterSingleOption from "../../components/Filter/FilterSingleOption";
import InputSearch from "../../components/Input/InputSearch";
import GrayButton from "../../components/Buttons/GrayButton";


const Cadastros = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedCadastro, setSelectedCadastro] = useState([]);
    const [openEdicaoCadastroModal, setOpenEdicaoCadastroModal] = useState(false);
	const [openCreateModal, setOpenCreateModal] = useState(false);
	const [optionCreateModal, setOptionCreateModal] = useState("");
	const [titleButton, setTitleButton] = useState("");
	const [title, setTitle] = useState("");
	const [loading, setLoading] = useState(false);
	const [tipoCadastro, setTipoCadastro] = useState("");
    const [dataToUpdate, setDataToUpdate] = useState({});
    const [searchSelected, setSearchSelected] = useState("Todos");
    const [searchSelectedOptions, setSearchSelectedOptions] = useState([]);
    const [searchPlaceholder, setSearchPlaceholder] = useState("");

    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const dispatch = useDispatch();
	const location = useLocation();

    useEffect(() => {
		if (estabelecimentoId !== 0) {
			if (location.search === "?type=clientes_fornecedores") {
				setTitleButton("Novo Cliente/Fornecedor");
				setTitle("Clientes/Fornecedores");
				setTipoCadastro("Fornecedor");
                setOptionCreateModal("fornecedor");
                setSearchSelectedOptions(["Física", "Jurídica", "Todos"]);
                setSearchPlaceholder("Tipo de cliente/fornecedor");
			}
			else if (location.search === "?type=centros_custo") {
				setTitleButton("Novo Centro de Custo");
				setTitle("Centros de Custo");
				setTipoCadastro("CentroCusto");
                setOptionCreateModal("centroCusto");
                setSearchSelected("");
			}
			else if (location.search === "?type=produtos_servicos") {
				setTitleButton("Novo Produto/Serviço");
				setTitle("Produtos/Serviços");
				setTipoCadastro("ProdutoServico");
                setOptionCreateModal("produtoServico");
                setSearchSelectedOptions(["Produto", "Serviço", "Todos"]);
                setSearchPlaceholder("Tipo de produto/serviço");
			}
            else if (location.search === "?type=categorias") {
                setTitleButton("Nova Categoria");
                setTitle("Categorias");
                setTipoCadastro("Categoria");
                setOptionCreateModal("categoria");
                setSearchSelectedOptions(["Despesa", "Receita", "Todos"]);
                setSearchPlaceholder("Tipo de categoria");
            }
		}
    }, [estabelecimentoId, location]);


    useEffect(() => {
        getCadastroInfo();
    }, [tipoCadastro, searchSelected]);


    const getCadastroInfo = () => {
		setLoading(true);        

        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            tipo_cadastro: tipoCadastro,
            search: searchSelected
        };

        api.GetCadastroInfo(dataRequest).then(response => {
            let responseData = response.data;
            setData(responseData.data);
            setColumns(responseData.columns);
			setLoading(false);
        }).catch(() => {
			dispatch(showSnackMessage({ message: "Erro ao buscar dados", severity: "error" }));
			setLoading(false);
        });
    };    

    const deleteCadastros = () => {
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            cadastros_ids: selectedCadastro,
            tipo_cadastro: tipoCadastro
        };

        api.DeleteCadastros(dataRequest).then((response) => {
            getCadastroInfo();
            dispatch(showSnackMessage({ message: response.data.message, severity: "success" }));
        }).catch(() => {
            
        });
    };

    const updateRow = (data) => {
        setDataToUpdate(data);
        setOpenEdicaoCadastroModal(true);
    };

    return (
        <div className="main">
            <Header title={title} />
            {
                loading ? (
                    <Skeleton variant="rectangular" height={"50vh"}/>
                ) : (
                    <Paper sx={{...paperStyle, backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}}>
                        <CreateOptionModal
                            openModal={openCreateModal}
                            setOpenModal={setOpenCreateModal}
                            option={optionCreateModal}
                            getCadastroInfo={getCadastroInfo}
                            getInputsOptions={() => ""}
                        />
                        <UpdateOptionModal
                            openModal={openEdicaoCadastroModal}
                            setOpenModal={setOpenEdicaoCadastroModal}
                            option={optionCreateModal}
                            data={dataToUpdate}
                            getCadastroInfo={getCadastroInfo}
                            tipoCadastro={tipoCadastro}
                            selectedCadastro={selectedCadastro}
                            setSelectedCadastro={setSelectedCadastro}
                        />
                        <Grid container sx={{display: "flex", justifyContent: "space-between", marginBottom: 2}}>
                            {
                                tipoCadastro === "CentroCusto" ? (
                                    <Grid item sx={{display: "flex", gap: 1}}>
                                        <InputSearch
                                            searchValue={searchSelected}
                                            setSearchValue={setSearchSelected}
                                            functionProps={() => ""}
                                        />
                                        <GrayButton
                                            title="Filtrar"
                                            onClick={() => getCadastroInfo()}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid item>
                                        <FilterSingleOption
                                            filterOptions={searchSelectedOptions}
                                            selectedOption={searchSelected}
                                            setSelectedOption={setSearchSelected}
                                            placeholder={searchPlaceholder}
                                        />
                                    </Grid>
                                )
                            }
                            <Grid item>
                                <YellowButton
                                    sx={{width: "200px"}}
                                    title={titleButton}
                                    onClick={() => setOpenCreateModal(true)}
                                />
                            </Grid>
                        </Grid>
                        <DataGridWithPagination
                            pagination
                            checked
                            rows={(row) => row.id}
                            columns={columns}
                            data={data}
                            customRowSelectionModelChange={(item) => setSelectedCadastro(item)}
                            cadastroItems={selectedCadastro}
                            deleteCadastros={deleteCadastros}
                            onRowClick={(row) => updateRow(row)}
                        />
                    </Paper>
                )
            }
        </div>
    );
};

export default Cadastros;