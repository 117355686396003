import React, {useEffect, useState} from "react";
import {smallPaperStyle, GRAY_LABEL_UX, styleTitleModal, BLUE_THEME, WHITE_ESCRITA_THEME, BORDER_TABLE, GRAY_STRONG_UX, GRAY_BORDER_UX, WHITE_THEME_BLACK} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import GrayButton from "../Buttons/GrayButton";
import YellowButton from "../Buttons/YellowButton";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { useSelector, useDispatch } from "react-redux";
import { showSnackMessage } from "../../actions/SnackActions";


const ImportOFXModal = (props) => {

    const {openModalOFX, setOpenModalOFX, sendExtratoOfx, selectedContaId} = props;

    const dispatch = useDispatch();
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const hiddenFileInput = React.useRef(null);
    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        if (!openModalOFX) {
            setSelectedFile(null);
            setIsSelected(false);
        }
    }, [openModalOFX]);

    const changeHandler = (event) => {
        let file = event.target.files[0];
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (file && fileExtension === "ofx") {
            setSelectedFile(file);
            setIsSelected(true);
        } else {
            dispatch(showSnackMessage({ message: "Tipo de arquivo inválido", severity: "error" }));
        }
    };

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    return (
        <Modal
            open={openModalOFX}
            onClose={() => setOpenModalOFX(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-testid="info-modal"
        >
            <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, width: "40%"}}>
                <Grid container>
                    <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                        <h3 style={{marginTop: 0, ...styleTitleModal, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: "700"}}>Importar OFX</h3>
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex", gap: 2, flexDirection: "row", flexWrap: "wrap"}}>
                        <span>
                            Insira o arquivo que será importado para a conta: <strong>{selectedContaId.label}</strong>.
                        </span>
                        <GrayButton
                            testId="button-anexar-certificado"
                            title="Anexar .ofx"
                            onClick={handleClick}
                            startIcon={<AttachFileOutlinedIcon/>}
                        />
                    </Grid>
                    <Grid item>
                        <input type="file"
                            accept=".ofx"
                            ref={hiddenFileInput}
                            onChange={changeHandler}
                            style={{display: "none", ml: 1}} 
                        />
                        {
                            isSelected &&
                                <Grid style={{...styles.styleFieldImport, color: temaEscuro && WHITE_THEME_BLACK, marginTop: 20}}>
                                    <span>Nome do arquivo: {selectedFile?.name}</span>
                                </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 5, display: "flex", gap: 2, alignItems: "center", justifyContent: "end"}}>
                        <GrayButton 
                            title="Cancelar"
                            onClick={() => setOpenModalOFX(false)}
                        />
                        <YellowButton
                            title="Confirmar"
                            onClick={() => sendExtratoOfx(selectedFile)}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
};

const styles = {
    styleFieldImport: {
        color : GRAY_STRONG_UX
    },
    formControl: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: GRAY_BORDER_UX
            },
        }
    },
};

export default ImportOFXModal;
